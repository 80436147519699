import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useFecha } from '../hooks/useFecha.js';
import axios from 'axios';
import logoPTM from "../../../images/newLogoPTM.jpeg";
import "./OfertaTurno.css";
import { useNavigate, useParams } from 'react-router-dom';
import { useUserContext } from '../../../useContexto.js';
import { Modal } from '../../components/Modal/Modal.js';
import { solicTurnoProfServices } from '../../SolicitudTurno/SolicitudTurnoMedico/services/solicTurnoProf.service.js';
import { SessionExpired } from '../../components/SessionExpired/SessionExpired.js';

const OfertaTurno = ({
    usuarioEnSesion,
    token,
    setEstaEnSesion,
    setUsuarioEnSesion,
    setToken,
    setEstaEnAjustes,
    setEstaEnContacto,
    setEstaEnPregFrec,
    showModal,
    handleOpenModal,
    handleCloseModal
}) => { // otra prop: usuarioEnSesion 
    const { register, setValue, reset, handleSubmit } = useForm();
    const navigate = useNavigate();
    const { fechaString, hora } = useFecha();

    // estados referidos al parametro id del turno generado por el link (aplica para AsignacionTurno, OfertaTurno, y ambas pantallas de confirmacion de turno)
    const [turnoSolicitado, setTurnoSolicitado] = useState({})
    const { id } = useParams();

    const [txtSolicitud, setTxtSolicitud] = useState(''); // esto sirve nomas para rednerizar por pantalla lo que decidio el profesional
    const [eligio, setEligio] = useState(false); // aca se almacena si el profesional hizo click en alguno de los 2 botones 
    const [decision, setDecision] = useState(''); // aca se almacena si el profesional acepto o rechazo el turno
    const [pulsoContinuar, setPulsoContinuar] = useState(false);
    const [tokenVencido, setTokenVencido] = useState(false)

    // efecto para persistencia de sesion
    useEffect(() => {
        const logguedUserJSON = window.localStorage.getItem("DoctorFy")
        if (logguedUserJSON) {
            const user = JSON.parse(logguedUserJSON);
            setUsuarioEnSesion(user);
            setToken(user.token)
            setEstaEnSesion(true);
        }
    }, [])

    const traerElTurno = async () => {
        if (window.location.pathname.includes('ofertaTurno')) {
            if (usuarioEnSesion) {
                const resultado = await solicTurnoProfServices.getTurnoById(id, token)
                if (resultado.error !== undefined) {
                    if (resultado.error === "TOKEN VENCIDO.") {
                        setTokenVencido(true);
                        return;
                    }
                    if (resultado.esCero) {
                        navigate("*")
                    }
                }
                setTurnoSolicitado(resultado)
            }
        }
    }

    // si esta en la pantalla de oferta de turno, seteando los datos como lo traido del backend
    useEffect(() => {
        traerElTurno()
    }, [usuarioEnSesion])


    // Actualizar el campo oculto con el boton que haya pulsado el medico.
    useEffect(() => {
        setValue('decision', decision); // Actualizar el campo oculto con el boton que haya pulsado el medico.
    }, [decision]);


    // funcion que se va a ejecuta en cuanto el usuario pulse el boton CONTINUAR, enviando el nuevo estado del turno al backend
    const onSubmit = async (data) => {
        let newData = {};
        if (data.decision === 'oferta aceptada') {
            // newData = {
            //     IdEstadoTurno: 4 // el paciente acepto el turno ofrecido
            // }
            // // await axios.put(`${apiUrl}/api/plataforma_medica/turnos/actualizar/${turnoSolicitado.IdTurno}`, newData);
            // const response = await solicTurnoProfServices.updateTurno(turnoSolicitado.IdTurno, newData, token)
            // if (response.error === "TOKEN VENCIDO.") {
            //     setTokenVencido(true);
            //     return;
            // }
            reset();
            setPulsoContinuar(true)
            navigate(`/pagos1/${id}`)
            // handleOpenModal()
        } else if (data.decision === 'oferta rechazada') { // esta parte no cambiaria porque no paga si rechaza el turno
            newData = {
                IdEstadoTurno: 8 // el paciente rechazo el turno ofrecido
            }
            const response = await solicTurnoProfServices.updateTurno(turnoSolicitado.IdTurno, newData, token)
            if (response.error === "TOKEN VENCIDO.") {
                setTokenVencido(true);
                return;
            }

            reset();
            setPulsoContinuar(true)
            handleOpenModal()
        }
    };

    const handleBack = () => {
        navigate('/misOfertasTurnoPac')
    }


    return (
        <>
            {usuarioEnSesion !== undefined && !tokenVencido ? (
                <>
                    <div className="form-registro-container row">
                        <form className="col s12" onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className='col s12 center'>
                                    <div className="img-container" id="imagen-en-formulario">
                                        <img src={logoPTM} alt="Logo Alimenta Salud" id="form-registro-img"></img>
                                    </div>
                                    <h5 id="header-form-registro" style={{ margin: 0 }}>OFERTA DE TURNO</h5>
                                </div>

                                <div className='col s12 center'>
                                    <button
                                        className='btn waves-effect waves-light'
                                        type="button"
                                        onClick={handleBack}
                                        style={{ marginBottom: '12px', marginTop: "4px" }}
                                    >
                                        VOLVER
                                    </button>
                                </div>

                                {(turnoSolicitado?.Usuario !== undefined) && (   // usuarioEnSesion !== null && 
                                    <>
                                        <p className='info-oferta'>Fecha: {fechaString}. HORA: {hora}</p>
                                        <p className='info-oferta'>Señor(a): {turnoSolicitado?.Usuario?.Nombre}  {turnoSolicitado?.Usuario?.Apellido}</p>
                                        <p className='info-oferta'>Le ofrecemos un turno bajo la modalidad {turnoSolicitado?.Modalidad} para el dia {turnoSolicitado?.Fecha}, a las {turnoSolicitado?.Hora}</p>
                                        <p className='info-oferta'>Con el/la Profesional: {turnoSolicitado?.Profesionale?.Nombre}  {turnoSolicitado?.Profesionale?.Apellido} cuyo número de matrícula es: {turnoSolicitado?.Profesionale?.NumeroMatricula}</p>
                                        <p className='info-oferta'>Especialista en:</p>
                                        <ul id="lista-esp-of">
                                            {turnoSolicitado?.Profesionale?.Especialidades?.map((e, index) => (
                                                <li key={index} className='info-oferta'>{e.Nombre}</li>
                                            ))}
                                        </ul>
                                        {/* <p></p> */}
                                        <p className='info-oferta'>Resumen Curricular: {turnoSolicitado?.Profesionale?.ResumenCurricular}</p>
                                        {turnoSolicitado?.Modalidad === "Presencial" && (
                                            <>
                                                <p className='info-oferta'>Lugar de atencion: {turnoSolicitado?.AtencionLugare?.Nombre}</p>
                                                <p className='info-oferta'>Direccion: {turnoSolicitado?.AtencionLugare?.Direccion}</p>
                                            </>
                                        )}

                                        {eligio === false && (
                                            <div className='col s12 center'>
                                                <div className='pregunta-oferta-container'>
                                                    <button
                                                        type="submit"
                                                        id='aceptaSolicitud'
                                                        className='botonSolicitud'
                                                        onClick={() => { setTxtSolicitud('OFERTA ACEPTADA'); setEligio(true); setDecision('oferta aceptada') }}
                                                    >
                                                        ACEPTAR
                                                    </button>

                                                    <button
                                                        type="submit"
                                                        id='rechazaSolicitud'
                                                        className='botonSolicitud'
                                                        onClick={() => { setTxtSolicitud('OFERTA RECHAZADA'); setEligio(true); setDecision('oferta rechazada') }}
                                                    >
                                                        RECHAZAR
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                        {eligio === true && (
                                            <>
                                                <div className='col s12 center'>
                                                    <span id='decision-solicitud' style={{ marginBottom: "8px" }}>{txtSolicitud}</span>
                                                </div>
                                                <div className='col s12 center'>
                                                    <button className='btn waves-effect waves-light' style={{ marginBottom: "80px", marginTop: "4px" }} disabled={pulsoContinuar}>
                                                        CONTINUAR
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </form>
                        {showModal && (
                            <Modal
                                title={decision === 'oferta aceptada' ? 'ACEPTASTE LA OFERTA DE TURNO' : 'RECHAZASTE LA OFERTA DE TURNO'}
                                description={decision === 'oferta aceptada' ? 'Estate atento a tu correo electrónico, pronto el profesional confirmará el turno' : 'Estate atento a tu correo electrónico por si recibís otra oferta de turno'}
                                onClose={() => { handleCloseModal(); navigate('/misOfertasTurnoPac') }}
                            />
                        )}
                    </div>
                </>
            ) : (
                <SessionExpired
                    setEstaEnSesion={setEstaEnSesion}
                    setToken={setToken}
                    setUsuarioEnSesion={setUsuarioEnSesion}
                    setEstaEnAjustes={setEstaEnAjustes}
                    setEstaEnPregFrec={setEstaEnPregFrec}
                    setEstaEnContacto={setEstaEnContacto}
                />
            )}
        </>
    );
};

export { OfertaTurno }