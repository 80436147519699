import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import logoPTM from "../../../../images/newLogoPTM.jpeg"
import './PsicoAdmision.css'
import axios from 'axios'
import { Modal } from '../../../components/Modal/Modal.js'
import { psicoAdmisionServices } from './services/psicoAdmision.service.js'
import { Tooltip } from "react-tooltip"
import { solicTurnoProfServices } from '../../../SolicitudTurno/SolicitudTurnoMedico/services/solicTurnoProf.service.js'
import { SessionExpired } from '../../../components/SessionExpired/SessionExpired.js'

const PsicoAdmision = ({
    apiUrl,
    usuarioEnSesion,
    token,
    setEstaEnSesion,
    setUsuarioEnSesion,
    setToken,
    setEstaEnAjustes,
    setEstaEnContacto,
    setEstaEnPregFrec,
    showModal,
    handleOpenModal,
    handleCloseModal
}) => {
    const navigate = useNavigate()
    const { control, reset, register, setValue, handleSubmit, formState: { errors } } = useForm()
    const { id } = useParams();

    // GESTION DE ESTADOS QUE ALMACENAN DATOS DEL BACKEND
    const [turnoConsultado, setTurnoConsultado] = useState({}) // el turno a atender
    const [psicoEspecialidades, setPsicoEspecialidades] = useState([]) // las especialidades disponibles
    const [profEspecialidad, setProfEspecialidad] = useState([]) // los profesionales de la especialidad elegida

    // GESTION DE LOS DATOS Y DEL DINAMISMO DEL FORMULARIO
    const [espSeleccionada, setEspSeleccionada] = useState(false) // almacenar en un estado si se eligio ya la especialidad
    const [espElegida, setEspElegida] = useState("") // almacenar que especialidad en especifico eligio
    const [profElegidos, setProfElegidos] = useState([]) // almacenar los profesionales que eligio el psico-admisor
    const [hizoClick, setHizoClick] = useState(false)
    const [selectedProfId, setSelectedProfId] = useState(""); // Estado para mantener el valor seleccionado del select

    // GESTION DE LA FECHA
    const fechaActual = new Date(); // Obtener la fecha actual
    const dia = fechaActual.getDate(); // Obtener el día, mes y año
    const mes = fechaActual.getMonth() + 1; // Ten en cuenta que los meses en JavaScript van de 0 a 11
    const año = fechaActual.getFullYear();
    const fechaFormateada = `${dia < 10 ? '0' + dia : dia}-${mes < 10 ? '0' + mes : mes}-${año}`; // Formatear la fecha

    // ERRORES EN EL FORMULARIO
    const [errorProfs, setErrorProfs] = useState(false)
    const [tokenVencido, setTokenVencido] = useState(false)

    // efecto para persistencia de sesion
    useEffect(() => {
        const logguedUserJSON = window.localStorage.getItem("DoctorFy")
        if (logguedUserJSON) {
            const user = JSON.parse(logguedUserJSON);
            setUsuarioEnSesion(user);
            setToken(user.token)
            setEstaEnSesion(true);
            setHizoClick(false)
        }
    }, []);

    const traerElTurno = async () => {
        if (window.location.pathname.includes('tomaConsultaPsicoAdmin')) {
            if (usuarioEnSesion) {
                const resultado = await solicTurnoProfServices.getTurnoById(id, token)
                if (resultado.error !== undefined) {
                    if (resultado.error === "TOKEN VENCIDO.") {
                        setTokenVencido(true);
                        return;
                    }
                    if (resultado.esCero) {
                        navigate("*")
                    }
                }
                setTurnoConsultado(resultado)
            }
        }
    }

    // PETICIONES AL BACKEND
    // traigo el turno a consultar
    useEffect(() => {
        traerElTurno()
    }, [usuarioEnSesion]);

    const traerPsicoEsps = async () => {
        const response = await psicoAdmisionServices.getEspecialidadesPsicoDisp(token)
        setPsicoEspecialidades(response)
    }
    useEffect(() => {
        traerPsicoEsps()
    }, [])

    // actualiza la especialidad elegida, su campo Disponible2 a 1, y trae TODOS LOS PROFESIONALES de esa especialidad,
    // sin depender de ningun campo
    const handleEspSeleccion = async (e) => {
        setEspSeleccionada(true);
        setEspElegida(parseInt(e.target.value))
        const response = await axios.get(`${apiUrl}/api/plataforma_medica/prof_esp/${parseInt(e.target.value)}`)
        setProfEspecialidad(response.data)
    };

    // para hacer el select dinamico de los profesionales elegidos
    const handleProfSeleccionado = (event) => {
        const idProfesionalSeleccionado = parseInt(event.target.value);
        const profesionalSeleccionado = profEspecialidad.find(prof => prof.Id === idProfesionalSeleccionado);

        // Filtrar los profesionales disponibles
        const nuevosProfEspecialidad = profEspecialidad.filter(prof => prof.Id !== idProfesionalSeleccionado);
        // Agregar el profesional seleccionado a los profesionales seleccionados
        setProfElegidos([...profElegidos, profesionalSeleccionado]);
        // Actualizar los profesionales disponibles
        setProfEspecialidad(nuevosProfEspecialidad);
        setSelectedProfId("");
    };

    // FUNCION QUE ENVIARA LOS DATOS DEL FORMULARIO AL BACKEND
    const onSubmit = async (data) => {
        setHizoClick(true)
        const [dia, mes, año] = fechaFormateada.split('-'); // la vuelvo a parsear porque SQL en el backend admite dateonly solo de la forma yyyy-mm-dd
        data.fecha = `${año}-${mes}-${dia}`
        data.esp = espElegida
        data.idTurno = parseInt(id)
        data.idPaciente = turnoConsultado.IdPaciente
        data.psicoAdmisor = usuarioEnSesion.Id
        if (profElegidos.length === 0) {
            setErrorProfs(true)
            setHizoClick(false)
            return
        } else {
            data.profesionales = profElegidos
        }
        setErrorProfs(false)
        const response = await psicoAdmisionServices.createConsultaPsicoAdmision(data, token)
        if (response.error === "TOKEN VENCIDO.") {
            setTokenVencido(true);
            setHizoClick(false)
            return;
        } else {
            await axios.put(`${apiUrl}/api/plataforma_medica/especialidades/updateDisp2/${espElegida}`)
        }
        setHizoClick(false)
        navigate("/salaEsperaProf")
    }

    return (
        <>
            {usuarioEnSesion !== undefined && !tokenVencido ? (
                <>
                    <div className='row'>
                        <div className=" col s12 logo-container center">
                            <img src={logoPTM} alt="Logo PTM" width="200" />
                        </div>

                        <div className='col s12 center'>
                            <h5 id="header-form-registro" className='center-align'>TOMA DE CONSULTA: PSICO-ADMISIÓN</h5>
                        </div>

                        {turnoConsultado?.Motivo === undefined ? (
                            <p>CARGANDO...</p>
                        ) : (
                            <>
                                <div className='col s12' id='lastTextTCPA'>
                                    {turnoConsultado?.IdProfesional !== null && (
                                        <span style={{ fontWeight: "500", display: "block" }}>Dr/Dra: {turnoConsultado?.Profesionale?.Nombre?.toUpperCase()} {turnoConsultado?.Profesionale?.Apellido?.toUpperCase()}</span>
                                    )}

                                    {fechaFormateada && (
                                        <span style={{ fontWeight: "500", display: "block" }}>Fecha: {fechaFormateada}</span>
                                    )}

                                    <br></br>

                                    <span style={{ fontWeight: "500", display: "block" }}>DATOS DEL PACIENTE: </span>
                                    {turnoConsultado?.IdPacAAtender !== null ? (
                                        <>
                                            <span style={{ fontWeight: "500", display: "block" }}>NOMBRE Y APELLIDO: {turnoConsultado?.PacientesNoUsuario?.Nombre?.toUpperCase()} {turnoConsultado?.PacientesNoUsuario?.Apellido?.toUpperCase()}</span>
                                            <span style={{ fontWeight: "500", display: "block" }}>DATOS DE COBERTURA DEL PACIENTE: </span>
                                            <ul>
                                                {turnoConsultado?.PacientesNoUsuario?.NumeroAfiliado !== null ? (
                                                    <>
                                                        <li><span style={{ fontWeight: "500", display: "block" }}>Numero de afiliado: {turnoConsultado?.PacientesNoUsuario?.NumeroAfiliado}</span></li>
                                                        {turnoConsultado?.PacientesNoUsuario?.Obras_Sociale !== null && (
                                                            <li>
                                                                <span style={{ fontWeight: "500", display: "block" }}>Obra social: {turnoConsultado?.PacientesNoUsuario?.Obras_Sociale?.Nombre}</span>
                                                                <span style={{ fontWeight: "500", display: "block" }}>Numero de plan de la obra social: {turnoConsultado?.PacientesNoUsuario?.NumerosPlane?.Nombre}</span>
                                                            </li>
                                                        )}
                                                        {turnoConsultado?.PacientesNoUsuario?.Empresas_Prepaga !== null && (
                                                            <li>
                                                                <span style={{ fontWeight: "500", display: "block" }}>Empresa de medicina prepaga: {turnoConsultado?.PacientesNoUsuario?.Empresas_Prepaga?.Nombre}</span>
                                                                <span style={{ fontWeight: "500", display: "block" }}>Numero de plan de la empresa de medicina prepaga: {turnoConsultado?.PacientesNoUsuario?.NumerosPlane?.Nombre}</span>
                                                            </li>
                                                        )}
                                                    </>
                                                ) : (
                                                    <li><span style={{ fontWeight: "500", display: "block" }}>El paciente no tiene ni obra social ni empresa de medicina prepaga</span></li>
                                                )}
                                            </ul>
                                        </>
                                    ) : (
                                        <>
                                            <span style={{ fontWeight: "500", display: "block" }}>NOMBRE Y APELLIDO: {turnoConsultado?.Usuario?.Nombre?.toUpperCase()} {turnoConsultado?.Usuario?.Apellido?.toUpperCase()}</span>
                                            <span style={{ fontWeight: "500", display: "block" }}>DATOS DE COBERTURA DEL PACIENTE: </span>
                                            <ul>
                                                {turnoConsultado?.Usuario?.NumeroAfiliado !== null ? (
                                                    <>
                                                        <li><span style={{ fontWeight: "500", display: "block" }}>Numero de afilidado: {turnoConsultado?.Usuario?.NumeroAfiliado}</span></li>
                                                        {turnoConsultado?.Usuario?.Obras_Sociale !== null && (
                                                            <li>
                                                                <span style={{ fontWeight: "500", display: "block" }}>Obra social: {turnoConsultado?.Usuario?.Obras_Sociale?.Nombre}</span>
                                                                <span style={{ fontWeight: "500", display: "block" }}>Numero de plan de la obra social: {turnoConsultado?.Usuario?.NumerosPlane?.Nombre}</span>
                                                            </li>
                                                        )}
                                                        {turnoConsultado?.Usuario?.Empresas_Prepaga !== null && (
                                                            <li>
                                                                <span style={{ fontWeight: "500", display: "block" }}>Empresa de medicina prepaga: {turnoConsultado?.Usuario?.Empresas_Prepaga?.Nombre}</span>
                                                                <span style={{ fontWeight: "500", display: "block" }}>Numero de plan de la empresa de medicina prepaga: {turnoConsultado?.Usuario?.NumerosPlane?.Nombre}</span>
                                                            </li>
                                                        )}
                                                    </>
                                                ) : (
                                                    <li><span style={{ fontWeight: "500", display: "block" }}>El paciente no tiene ni obra social ni empresa de medicina prepaga</span></li>
                                                )}
                                            </ul>
                                            <br></br>
                                        </>
                                    )}

                                    {turnoConsultado?.PacientesNoUsuario !== null && turnoConsultado?.Usuario !== null && (
                                        <>
                                            <span style={{ fontWeight: "500", display: "block" }}>DATOS DEL USUARIO SOLICITANTE DEL TURNO: </span>
                                            <span style={{ fontWeight: "500", display: "block" }}>NOMBRE Y APELLIDO: {turnoConsultado?.Usuario?.Nombre?.toUpperCase()} {turnoConsultado?.Usuario?.Apellido?.toUpperCase()}</span>
                                            <br></br>
                                        </>
                                    )}

                                    {turnoConsultado?.Motivo !== null && (
                                        <span style={{ fontWeight: "500", display: "block" }}>Motivo de la consulta: {turnoConsultado?.Motivo}</span>
                                    )}
                                </div>

                                <div className='col s12'>
                                    <label htmlFor="diagnostico" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Diagnóstico: </label>
                                    <textarea
                                        type="text"
                                        id="diagnostico"
                                        name="diagnostico"
                                        className='validate'
                                        style={{ fontWeight: "bold" }}
                                        {...register
                                            ('diagnostico',
                                                {
                                                    required: {
                                                        value: true,
                                                        message: "El diagnóstico es requerido."
                                                    },
                                                    minLength: {
                                                        value: 10,
                                                        message: "El diagnóstico debe tener una longitud mínima de 10 caracteres"
                                                    },
                                                }
                                            )
                                        }
                                    />
                                </div>
                                {errors.diagnostico && <div className='col s12'>
                                    <span className='error-message'>{errors.diagnostico.message}</span></div>}

                                <div className='col s6' style={{ marginBottom: espElegida ? '0px' : '80px' }}>
                                    <label htmlFor="esp" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Especialidades Habilitadas: </label>
                                    <select
                                        className="browser-default validate"
                                        id="esp"
                                        name="esp"
                                        defaultValue=""
                                        style={{ fontWeight: "bold" }}
                                        disabled={espSeleccionada}
                                        onChange={(e) => { handleEspSeleccion(e); }}
                                    >
                                        <option value="" disabled style={{ fontWeight: "bold" }}>Elegí una opcion</option>
                                        {psicoEspecialidades.map((e, index) => (
                                            <option key={index} value={e.Id} style={{ fontWeight: "bold" }}>{e.Nombre}</option>
                                        ))}
                                    </select>
                                </div>

                                {/* { ...register('profs', { required: { value: true, message: "La especialidad es requerida" } }) } */}
                                {espElegida && (
                                    <>
                                        <div className='col s6' style={{ marginBottom: espElegida ? '0px' : '80px' }}>
                                            <label htmlFor="profs" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Profesionales Habilitados: </label>
                                            <select style={{ fontWeight: "bold" }} className="browser-default validate" id="profs" name="profs" value={selectedProfId} onChange={handleProfSeleccionado}>
                                                <option value="" disabled style={{ fontWeight: "bold" }}>Elegí una opcion</option>
                                                {profEspecialidad.map((e, index) => (
                                                    <option key={index} value={e.Id} style={{ fontWeight: "bold" }}>{e.Nombre} {e.Apellido}</option>
                                                ))}
                                            </select>
                                        </div>

                                        {/* <div className='col s12 center'>
                                    <button type="button" id="addProf" className='btn waves-effect waves-light' disabled={profElegidos.length === 0}>
                                        Agregar Profesional
                                    </button>
                                </div> */}

                                        <div className='col s12'>
                                            <p style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Profesionales Seleccionados</p>
                                            <ul>
                                                {profElegidos.map((prof, index) => (
                                                    <li key={index}><span style={{ fontWeight: "500", display: "block" }}>{prof.Nombre} {prof.Apellido}</span></li>
                                                ))}
                                            </ul>
                                        </div>

                                        {errorProfs && <span className='error-message-2'>ERROR. Por favor, indicá al menos un profesional</span>}

                                        <div className="col s12 center">
                                            <button
                                                className='btn waves-effect waves-light green darken-3'
                                                id="msj"
                                                type="submit"
                                                onClick={handleSubmit(onSubmit)}
                                                style={{ marginTop: "20px", marginBottom: "100px" }}
                                                data-tooltip-id="tooltip-FC"
                                                data-tooltip-content="FINALIZAR CONSULTA"
                                                disabled={hizoClick}
                                            >
                                                <i className='material-icons large'>done</i>
                                            </button>
                                            <Tooltip
                                                id='tooltip-FC'
                                                style={{ zIndex: 1 }}
                                            />
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </div>


                    {showModal && (
                        <Modal
                            title={'RECETA ELECTRÓNICA'}
                            description={'Estimado/a Doctor/a: Para emitir su receta, por favor, inicie sessión en el aplicativo correpondiente'}
                            onClose={handleCloseModal}
                        />
                    )}
                </>
            ) : (
                <SessionExpired
                    setEstaEnSesion={setEstaEnSesion}
                    setToken={setToken}
                    setUsuarioEnSesion={setUsuarioEnSesion}
                    setEstaEnAjustes={setEstaEnAjustes}
                    setEstaEnPregFrec={setEstaEnPregFrec}
                    setEstaEnContacto={setEstaEnContacto}
                />
            )}
        </>
    )
}

export { PsicoAdmision }