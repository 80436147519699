import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';

const getDetailTurno = async (idTurno, token) => {
    try {
        const response = await axios.get(`${apiUrl}/api/plataforma_medica/turnos/turno/${idTurno}`, { headers: { Authorization: `Bearer ${token}` } })
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data
        }
    }
}

const detailTurnoServices = {
    getDetailTurno
}

export { detailTurnoServices }