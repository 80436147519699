import React, { useState, useEffect } from 'react'
import logoPTM from "../../../images/newLogoPTM.jpeg"
import { useNavigate } from 'react-router-dom'
import { turnosSalaEsperaForProfesionalServices } from './services/salaEsperaProf.service.js'
import axios from 'axios'
import { SessionExpired } from '../../components/SessionExpired/SessionExpired.js'

const SalaEsperaProf = ({ usuarioEnSesion, token, setEstaEnSesion, setUsuarioEnSesion, setToken, setEstaEnAjustes, setEstaEnContacto, setEstaEnPregFrec }) => {
    const navigate = useNavigate()

    const [turnosEnSalaEspera, setTurnosEnSalaEspera] = useState([])
    const [hizoClick, setHizoClick] = useState(false)
    const [tokenVencido, setTokenVencido] = useState(false)

    // efecto para persistencia de sesion
    useEffect(() => {
        const logguedUserJSON = window.localStorage.getItem("DoctorFy")
        if (logguedUserJSON) {
            const user = JSON.parse(logguedUserJSON);
            setUsuarioEnSesion(user);
            setToken(user.token)
            setEstaEnSesion(true);
        }
    }, [])

    const traerTurnosDeProfesional = async () => {
        if (usuarioEnSesion) {
            const response = await turnosSalaEsperaForProfesionalServices.getTurnosEnSalaEsperaForProfesional(usuarioEnSesion.Id, token)
            if (response.error) {
                if (response.error === "TOKEN VENCIDO.") {
                    setTokenVencido(true);
                    return;
                }
            }
            setTurnosEnSalaEspera(response)
        }
        setHizoClick(false)
    }

    const handleBack = () => {
        navigate("/bienvenida")
        setHizoClick(false)
    }

    // trae los turnos que ya fueron confirmados por ambas partes desde el backend.
    useEffect(() => {
        traerTurnosDeProfesional()
    }, [usuarioEnSesion])

    const onSubmit = async (id) => {
        const data = {}
        data.IdEstadoTurno = 12

        // actualizo el turno como que ya no esta en sala de espera
        // const response = await turnosSalaEsperaForProfesionalServices.updateTurno(id, data, token)
        // if (response.error) {
        //     if (response.error === "TOKEN VENCIDO.") {
        //         setTokenVencido(true);
        //         return;
        //     }
        // }

        // si el turno es de especialidad PSICOLOGIA ADMISION
        const turnoSeleccionadoParaAtender = await turnosSalaEsperaForProfesionalServices.getTurnoActualizado(id, token)
        if (turnoSeleccionadoParaAtender.error) {
            if (turnoSeleccionadoParaAtender.error === "TOKEN VENCIDO.") {
                setTokenVencido(true);
                return;
            }
        }

        if (turnoSeleccionadoParaAtender.Especialidade.Nombre === "Psicología Admisión") {
            navigate(`/tomaConsultaPsicoAdmin/${id}`)
        } else {
            // en caso de que la especialidad del turno NO SEA PSICOLOGIA ADMISION, se va a esta pantalla
            navigate(`/tomaConsulta/${id}`)
        }
    }

    return (
        <>
            {usuarioEnSesion !== undefined && !tokenVencido ? (
                <>
                    <div className='col s12 center'>
                        <div className="img-container" id="imagen-en-formulario">
                            <img src={logoPTM} alt="Logo Alimenta Salud" id="form-registro-img"></img>
                        </div>
                        <h5 id="header-form-registro">SALA DE ESPERA</h5>
                        <h4 className="center-align" style={{ color: 'blue' }}>{usuarioEnSesion?.Nombre?.toUpperCase()} {usuarioEnSesion?.Apellido?.toUpperCase()}</h4>
                    </div>

                    <div className='col s12 center'>
                        <button
                            className='btn waves-effect waves-light'
                            type="button"
                            onClick={handleBack}
                            style={{ marginBottom: '24px' }}
                        >
                            VOLVER
                        </button>
                    </div>

                    {/* SI EL PROFESIONAL TIENE TURNOS CONFIRMADOS LE MUESTRA ESTA TABLA, SI NO, LE MUESTRA UN MENSAJE  */}
                    {turnosEnSalaEspera?.error === undefined ? (
                        <div className="col s12 center-align" id='tablaTurnos'>
                            <table className="striped responsive-table" style={{ marginBottom: '100px' }}>
                                <thead>
                                    <tr>
                                        <th>FECHA</th>
                                        <th>HORA</th>
                                        <th>PACIENTE</th>
                                        <th>MOTIVO</th>
                                        <th>MODALIDAD</th>
                                        <th>VER DETALLE</th>
                                        <th>TOMAR CONSULTA</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {turnosEnSalaEspera.map((registro, index) => (
                                        <tr key={index}>
                                            <td style={{ fontWeight: "500" }}>{registro.Fecha}</td>
                                            <td style={{ fontWeight: "500" }}>{registro.Hora}</td>
                                            <td style={{ fontWeight: "500" }}>{registro.Usuario?.Nombre?.toUpperCase()} {registro.Usuario?.Apellido?.toUpperCase()}</td>
                                            <td style={{ fontWeight: "500" }}>{registro.Motivo}</td>
                                            <td style={{ fontWeight: "500" }}>{registro.Modalidad}</td>
                                            <td>
                                                <button className='btn waves-effect waves-light' onClick={() => navigate(`/detalleTurno/${registro.IdTurno}`)}>
                                                    VER DETALLE
                                                </button>
                                            </td>
                                            <td>
                                                <button className='btn waves-effect waves-light' onClick={async () => await onSubmit(registro.IdTurno)} disabled={hizoClick}>
                                                    TOMAR CONSULTA
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (<div className="col s12 center-align"><h2>NO HAY TURNOS EN SALA DE ESPERA</h2 ></div >)}
                </>
            ) : (
                <SessionExpired
                    setEstaEnSesion={setEstaEnSesion}
                    setToken={setToken}
                    setUsuarioEnSesion={setUsuarioEnSesion}
                    setEstaEnAjustes={setEstaEnAjustes}
                    setEstaEnPregFrec={setEstaEnPregFrec}
                    setEstaEnContacto={setEstaEnContacto}
                />
            )}
        </>
    )
}


export { SalaEsperaProf }