import React from 'react'
import { useNavigate } from 'react-router-dom'
import logoPTM from "../../../images/newLogoPTM.jpeg"

const PreguntasFrecuentesPac = ({ setEstaEnPregFrec }) => {
    const navigate = useNavigate()

    const handleBack = () => {
        setEstaEnPregFrec(false)
        navigate(-1)
    }

    return (
        <>
            <div className="row">
                <div className="col s12 m6 offset-m3">
                    <div className="logo-container center">
                        <img src={logoPTM} alt="Logo PTM" width="200" />
                    </div>
                    <h4 className="center-align" style={{ color: 'blue' }}>FUNCIONALIDAD TEMPORALMENTE NO DISPONIBLE</h4>
                    <div className='col s12 center'>
                        <button
                            className='btn waves-effect waves-light'
                            type="button"
                            onClick={handleBack}
                            style={{ marginTop: "20px" }}
                        >
                            VOLVER
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export { PreguntasFrecuentesPac }