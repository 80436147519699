import React, { useState, useEffect } from 'react';
import './SolicitudTurnoParaOtro.css';
import "../SolicitudTurnoPaciente/SolicitudTurnoPaciente.css"
import logoPTM from '../../../images/newLogoPTM.jpeg';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ciudadesService } from '../../Registro/services/ciudades.service.js';
import { Modal } from '../../components/Modal/Modal.js';
import { useFecha } from '../../Turnos/hooks/useFecha.js';
import { obrasSocialesServices } from '../../Registro/services/obrasSociales.service';
import { empresasPrepagaServices } from '../../Registro/services/empresasPrepaga.service';
import { solicTurnoParaOtroServices } from './services/solicTurnoPacParaOtro.service.js';
import { SessionExpired } from '../../components/SessionExpired/SessionExpired.js';

const SolicitudTurnoParaOtro = ({
    apiUrl,
    usuarioEnSesion,
    token,
    setEstaEnSesion,
    setUsuarioEnSesion,
    setToken,
    setEstaEnAjustes,
    setEstaEnContacto,
    setEstaEnPregFrec,
    showModal,
    handleOpenModal,
    handleCloseModal,
}) => {
    const { fechaString, hora } = useFecha();
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
    const navigate = useNavigate();

    // estos estados se usaran para mostrar por pantalla lo que haya indicado el usuairo, tal como solicita el requerimiento
    const [especilaidadElegida, setEspecialidadElegida] = useState('');
    const [ciudad, setCiudad] = useState('');
    const [modalidadElegida, setModalidadElegida] = useState(''); // al ser botones y no inputs de tipo radio, si o si debo almacenar la modalidadElegida en un estado y al valor de ese estado 'meterlo' dentro de los datos del formulario
    const [noHayMedicos, setNoHayMedicos] = useState(false) // por defecto si hay medicos
    const [personaElegida, setPersonaElegida] = useState(0);

    // estados para almacenar informacion proveniente del backend en cuanto se renderiza el componente
    const [todasEspecialidades, setTodasEspecialidades] = useState([]);
    const [allCiudades, setAllCiudades] = useState([]);

    // estados para gestionar el registro de obra social y/o empresa de medicina prepaga
    const [todasOS, setTodasOS] = useState([]);
    const [todasEP, setTodasEP] = useState([]);
    const [OSyEPElegida, setOSyEPElegida] = useState('');
    const [planesPosibles, setPlanesPosibles] = useState([]);
    const [OSElegida, setOSElegida] = useState(0);
    const [EPElegida, setEPElegida] = useState(0);
    const [idNroPlan, setIdNroPlan] = useState(0);
    const [norAfiliado, setNroAfilado] = useState('');
    const [errorOSEP, setErrorOSEP] = useState(false);
    const [errorPac, setErrorPac] = useState(false);
    const [tokenVencido, setTokenVencido] = useState(false)
    const [hizoClick, setHizoClick] = useState(false)
    const [datosEspElegida, setDatosEspElegida] = useState(null)

    // efecto para persistencia de sesion
    useEffect(() => {
        const logguedUserJSON = window.localStorage.getItem("DoctorFy")
        if (logguedUserJSON) {
            const user = JSON.parse(logguedUserJSON);
            setUsuarioEnSesion(user);
            setToken(user.token)
            setEstaEnSesion(true);
        }
    }, [])


    // traer todas las OS desde el backend
    useEffect(() => {
        const traerOS = async () => {
            const OSTraidas = await obrasSocialesServices.getAll();
            setTodasOS(OSTraidas)
        }
        traerOS()
    }, []);

    // traer todas las empresas prepagas desde el backend
    useEffect(() => {
        const traerEP = async () => {
            const EPTraidas = await empresasPrepagaServices.getAll();
            setTodasEP(EPTraidas)
        }
        traerEP();
    }, [])

    // traer todas las especialidades medicas desde el backend
    useEffect(() => {
        const traerEspecialidades = async () => {
            if (usuarioEnSesion) {
                const especialidadesTraidas = await axios.get(`${apiUrl}/api/plataforma_medica/especialidades/disps`, { headers: { Authorization: `Bearer ${token}` } });
                setTodasEspecialidades(especialidadesTraidas.data)
            }
        };
        traerEspecialidades();
    }, [usuarioEnSesion]);

    // traer todas las ciudades desde el backend
    const traerCiudades = async (idEspecialidad) => {
        const ciudadesTraidas = await ciudadesService.getCiudadesForTurnos(idEspecialidad);
        setAllCiudades(ciudadesTraidas)
    }

    useEffect(() => {
        const getCities = async () => {
            if (datosEspElegida) {
                await traerCiudades(datosEspElegida.Id)
            }
        }
        getCities()
    }, [datosEspElegida])

    useEffect(() => {
        const traerPlanesPosibles = async () => {
            if (OSyEPElegida != 0) {
                const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:4000'; // declaracion de la variable de entorno de la API
                // traer los planes posibles para lo que haya marcado el usuario
                const planesTraidos = await axios.get(`${baseURL}/api/plataforma_medica/numerosPlanes/deObraSocialYDeEmpresaEspecifica/${OSElegida}/${EPElegida}`);
                setPlanesPosibles(planesTraidos.data)
            }
        }
        traerPlanesPosibles()
    }, [OSElegida, EPElegida])

    // estados de validaciones
    const [eligioModalidad, setEligioModalidad] = useState(false);
    const [errorModalidad, setErrorModalidad] = useState(false);

    // Actualizar el campo oculto con la modalidad seleccionada
    useEffect(() => {
        setValue('modalidad', modalidadElegida); // Actualizar el campo oculto con la modalidad seleccionada
    }, [modalidadElegida]);

    // en caso de que el usuario quiera cambiar algo, que se resetee todo el formulario
    const handleCambiar = () => {
        reset(); // Resetear el formulario y limpiar los campos registrados
        setEligioModalidad(false);
        setEspecialidadElegida('');
        setCiudad('');
        setModalidadElegida('');
        setPersonaElegida(0)
    };

    // funcion que se ejecuta si el usuario hace click en VOLVER
    const handleBack = () => {
        reset();
        setErrorModalidad(false);
        setEligioModalidad(false);
        setEspecialidadElegida('');
        setCiudad('');
        setModalidadElegida('');
        setTodasEspecialidades([]);
        setPersonaElegida(0)
        navigate("/bienvenida");
        setOSyEPElegida('');
        setOSElegida(0)
        setEPElegida(0)
        setIdNroPlan(0)
        setNroAfilado('')
        setErrorOSEP(false)
    }

    // hacer que la fecha que ingrese el usuario sea la actual o bien 100 años menos como limite a la hora de registrarse
    const fechaActual = new Date();
    const fechaMinima = new Date();
    fechaMinima.setFullYear(fechaActual.getFullYear() - 100);
    const fechaActualString = fechaActual.toISOString().split('T')[0];
    const fechaMinimaString = fechaMinima.toISOString().split('T')[0];

    // en cuanto el usuario pulse solicitar turno, se ejecuta esta funcion, que es la que envia los datos del turno al backend para su creacion
    const onSubmit = async (data) => {
        setHizoClick(true)
        if (OSyEPElegida === '1' && (EPElegida === 0 || idNroPlan === 0)) {
            setErrorOSEP(true);
            return
        };

        if (OSyEPElegida === '2' && (OSElegida === 0 || idNroPlan === 0)) {
            setErrorOSEP(true);
            return
        };

        if (OSyEPElegida === '3' && (EPElegida === 0 || OSElegida === 0 || idNroPlan === 0)) {
            setErrorOSEP(true);
            return
        };

        // si el usuario no indico una modalidad de atencion, no se ejecuta nada y devuelve error
        if (modalidadElegida === '') {
            setErrorModalidad(true);
            return;
        }

        // ajustando el objeto data que es el que se va a enviar al backend para crear el turno
        data.fecha = fechaString; // Agregar la fecha al objeto data que es el que se va a enviar al backend
        data.hora = hora; // Agregar la hora al objeto data que es el que se va a enviar al backend
        // data.idPaciente = usuarioEnSesion.usuarioComun.Id // Agregar el Id de paciente (usuario) que esta solicitando el turno
        data.especialidad = parseInt(data.especialidad) // Agregar la especialidad del turno
        data.ciudad = parseInt(data.ciudad)
        data.dniPacNoUser = parseInt(data.dniPacNoUser) === 1 ? parseInt(data.dniPaciente) : parseInt(data.dniPacNoUser); // es el dni en realidad, pero se lo toma como si fuese un id

        data.numeroAfiliadoPaciente = null;
        data.idNroPlanPaciente = null;
        data.idObraSocialPaciente = null;
        data.idEmpresaPrepagaPaciente = null;

        if (OSElegida !== 0 || EPElegida !== 0) {
            data.numeroAfiliadoPaciente = norAfiliado;
            data.idNroPlanPaciente = idNroPlan;

            if (OSElegida !== 0) {
                data.idObraSocialPaciente = OSElegida;
            }

            if (EPElegida !== 0) {
                data.idEmpresaPrepagaPaciente = EPElegida;
            }
        }
        delete data.dniPaciente
        if (datosEspElegida) {
            if (datosEspElegida.esPsico) {
                data.esPsico = true
            }
        }

        // TOKEN ACA
        const medicosPosibles = await solicTurnoParaOtroServices.getMedicosParaTurno(data, token)
        if (medicosPosibles.error) {
            if (medicosPosibles.error === "TOKEN VENCIDO.") {
                setTokenVencido(true);
                return;
            }
        }

        if (medicosPosibles.noHay !== undefined) { // si no hay medicos de la misma ciudad y especialidad
            reset();
            setErrorModalidad(false);
            setEligioModalidad(false);
            setEspecialidadElegida('');
            setCiudad('');
            setModalidadElegida('');
            handleOpenModal()
            setNoHayMedicos(true)
            setOSyEPElegida('');
            setOSElegida(0)
            setEPElegida(0)
            setIdNroPlan(0)
            setNroAfilado('')
            setErrorOSEP(false)
            return;
        }
        setNoHayMedicos(false)


        // haciendo el POST al backend con axios. TOKEN ACA
        const creacionTurno = await solicTurnoParaOtroServices.createTurnoParaOtro(data, token)

        if (creacionTurno.errorPac) {
            setErrorPac(true);
            return;
        }
        setErrorPac(false);
        // resetea los datos del formulario una vez ya enviados los datos, y tambien resetea los estados involucrados en el formulario
        reset();
        setErrorModalidad(false);
        setEligioModalidad(false);
        setEspecialidadElegida('');
        setCiudad('');
        setModalidadElegida('');
        handleOpenModal();
        setPersonaElegida(0)

        setOSyEPElegida('');
        setOSElegida(0)
        setEPElegida(0)
        setIdNroPlan(0)
        setNroAfilado('')
        setErrorOSEP(false)
        setHizoClick(false)
    };

    return (
        <>
            {usuarioEnSesion !== undefined && !tokenVencido ? (
                <>
                    <div className="form-registro-container row">
                        <form className="col s12" onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className='col s12 center'>
                                    <div className="img-container" id="imagen-en-formulario">
                                        <img src={logoPTM} alt="Logo Alimenta Salud" id="form-registro-img"></img>
                                    </div>
                                    <h5 id="header-form-registro">SOLICITUD DE TURNO PARA OTRO</h5>
                                </div>

                                <div className='col s6 l4'>
                                    <label htmlFor='otro' style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Para quien?</label>
                                    <select className='browser-default validate' id='otro' name='otro' defaultValue="" {...register('dniPacNoUser', { required: true })} onChange={(e) => { setPersonaElegida(e.target.value) }} style={{ marginBottom: "1px", fontWeight: "bold" }}>
                                        <option value="" disabled style={{ fontWeight: "bold" }}>Elegí una opcion</option>
                                        {usuarioEnSesion?.PacientesNoUsuarios.map((e, index) => (
                                            <option key={index} value={e.DniPaciente} style={{ fontWeight: "bold" }}>{e.Nombre} {e.Apellido}</option>
                                        ))}
                                        <option value={1} style={{ fontWeight: "bold" }}>Nuevo</option>
                                    </select>
                                    {errors.dniPacNoUser && <span className='error-message'>Error, tenes que elegir a quien le queres pedir un turno</span>}
                                </div>

                                {personaElegida == 1 && (
                                    <>
                                        <div className='col s6 l4'>
                                            <label htmlFor='dniPaciente' style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>DNI del paciente</label>
                                            <input
                                                id='dniPaciente'
                                                name='dniPaciente'
                                                className='validate'
                                                style={{ fontWeight: "bold", marginBottom: "0px" }}
                                                {...register('dniPaciente', {
                                                    required: {
                                                        value: true,
                                                        message: 'Por favor, ingresa el DNI del paciente'
                                                    },
                                                    maxLength: {
                                                        value: 8,
                                                        message: 'El DNI no puede tener mas de 8 caracteres'
                                                    },
                                                    minLength: {
                                                        value: 7,
                                                        message: 'El DNI no puede tener menos de 7 caracteres'
                                                    },
                                                    pattern: {
                                                        value: /^[0-9]+$/,
                                                        message: 'Solo se permiten números positivos'
                                                    }
                                                })}
                                            />
                                            {errors.dniPaciente && <span className='error-message'>{errors.dniPaciente.message}</span>}
                                        </div>

                                        <div className='col s6 l4'>
                                            <label htmlFor='nombrePac' style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Nombre del paciente</label>
                                            <input
                                                id="nombrePac"
                                                name="nombrePac"
                                                type="text"
                                                style={{ fontWeight: "bold", marginBottom: "0px" }}
                                                className="validate"
                                                {...register('nombrePaciente', {
                                                    required: {
                                                        value: true,
                                                        message: 'Por favor, pone el nombre del paciente'
                                                    },
                                                    maxLength: {
                                                        value: 100,
                                                        message: 'El nombre no puede tener mas de 100 caracteres'
                                                    }
                                                })}
                                            />
                                            {errors.nombrePaciente && <span className='error-message'>{errors.nombrePaciente.message}</span>}
                                        </div>

                                        <div className='col s6 l4'>
                                            <label htmlFor='apellidoPac' style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Apellido del paciente</label>
                                            <input
                                                id="apellidoPac"
                                                name="apellidoPac"
                                                type="text"
                                                style={{ fontWeight: "bold", marginBottom: "0px" }}
                                                className="validate"
                                                {...register('apellidoPaciente', {
                                                    required: {
                                                        value: true,
                                                        message: 'Por favor, pone el apellido del paciente'
                                                    },
                                                    maxLength: {
                                                        value: 100,
                                                        message: 'El apellido no puede tener mas de 100 caracteres'
                                                    }
                                                })}
                                            />
                                            {errors.apellidoPaciente && <span className='error-message'>{errors.apellidoPaciente.message}</span>}
                                        </div>

                                        <div className='col s6 l4'>
                                            <label htmlFor='emailPac' style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Correo Electronico del paciente</label>
                                            <input
                                                id="emailPac"
                                                name="emailPac"
                                                type="email"
                                                style={{ fontWeight: "bold", marginBottom: "0px" }}
                                                className="validate"
                                                {...register('correoElectronicoPaciente', {
                                                    required: {
                                                        value: false,
                                                    },
                                                    pattern: {
                                                        value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
                                                        message: 'Error. Ingresa una direcicon de mail valida porfa',
                                                    }
                                                })}
                                            />
                                            {errors.correoElectronicoPaciente && <span className='error-message'>{errors.correoElectronicoPaciente.message}</span>}
                                        </div>

                                        <div className='col s6 l4'>
                                            <label htmlFor='phonePac' style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Numero de Telefono del paciente</label>
                                            <input
                                                id='phonePac'
                                                name='phonePac'
                                                type='text'
                                                style={{ fontWeight: "bold", marginBottom: "0px" }}
                                                className="validate"
                                                {...register('numeroTelefonoPaciente', {
                                                    required: {
                                                        value: false,
                                                    },
                                                    maxLength: {
                                                        value: 13,
                                                        message: 'El numero de telefono no puede tener mas de 13 caracteres'
                                                    },
                                                    minLength: {
                                                        value: 10,
                                                        message: 'El numero de telefono no puede tener menos de 10 caracteres'
                                                    },
                                                    pattern: {
                                                        value: /^\+?\d+$/,
                                                        message: 'El numero de telefono debe ser valido'
                                                    }
                                                })}
                                            />
                                            {errors.numeroTelefonoPaciente && <span className='error-message'>{errors.numeroTelefonoPaciente.message}</span>}
                                        </div>

                                        <div className='col s12'>
                                            <label htmlFor='domicilioPaciente' style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Domicilio del Paciente (si viven juntos, esto no es necesario)</label>
                                            <textarea
                                                id='domicilioPaciente'
                                                name='domicilioPaciente'
                                                className='materialize-textarea validate'
                                                style={{ fontWeight: "bold", marginBottom: "0px" }}
                                                {...register('domicilioPaciente', {
                                                    required: {
                                                        value: false
                                                    },
                                                    maxLength: {
                                                        value: 200,
                                                        message: 'El domicilio solo puede tener hasta 200 caracteres'
                                                    }
                                                })}
                                            />
                                            {errors.domicilioPaciente && <span className='error-message'>{errors.domicilioPaciente.message}</span>}
                                        </div>

                                        <div className='col s6 l4'>
                                            <label htmlFor="sexoPac" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Sexo del Paciente</label>
                                            <select
                                                className="browser-default validate"
                                                id="sexoPac"
                                                style={{ fontWeight: "bold", marginBottom: "1px" }}
                                                name="sexoPac"
                                                defaultValue=""
                                                {...register('sexoPaciente', {
                                                    required: {
                                                        value: true,
                                                        message: 'Error. Por favor, elegi el sexo del paciente'
                                                    }
                                                })}
                                            >
                                                <option value="" disabled style={{ fontWeight: "bold" }}>Elegí una opcion</option>
                                                <option value="Masculino" style={{ fontWeight: "bold" }}>Masculino</option>
                                                <option value="Femenino" style={{ fontWeight: "bold" }}>Femenino</option>
                                            </select>
                                            {errors.sexoPaciente && <span className='error-message'>{errors.sexoPaciente.message}</span>}
                                        </div>

                                        <div className='col s6 l4'>
                                            <label htmlFor='fnPac' style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Fecha de Nacimiento</label>
                                            <input
                                                type='date'
                                                style={{ fontWeight: "bold", marginBottom: "0px" }}
                                                className="datepicker validate"
                                                id='fnPac'
                                                name='fnPac'
                                                min={fechaMinimaString}
                                                max={fechaActualString}
                                                {...register('fechaNacimientoPaciente', {
                                                    required: {
                                                        value: true,
                                                        message: 'Por favor, ingresa la fecha de nacimiento completa del paciente'
                                                    }
                                                })}
                                            />
                                            {errors.fechaNacimientoPaciente && <span className='error-message'>{errors.fechaNacimientoPaciente.message}</span>}
                                        </div>

                                        {/* <div className='col s12'>
                                            <label htmlFor="eleccionEPuOS" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Tiene Obra Social o paga alguna mensualidad a una empresa de medicina prepaga? "Si no tiene ninguna, no elija"</label>
                                            <select style={{ fontWeight: "bold", marginBottom: "1px" }} className="browser-default validate" id="eleccionEPuOS" name="eleccionEPuOS" defaultValue="0" onChange={(e) => setOSyEPElegida(e.target.value)}>
                                                <option value="0" disabled style={{ fontWeight: "bold" }}>Elegí una opcion</option>
                                                <option value="1" style={{ fontWeight: "bold" }}>Tengo Empresa Prepaga</option>
                                                <option value="2" style={{ fontWeight: "bold" }}>Tengo Obra Social</option>
                                                <option value="3" style={{ fontWeight: "bold" }}>Tengo Ambas</option>
                                            </select>
                                        </div>

                                        {(OSyEPElegida === "1" || OSyEPElegida === "3") && (
                                            <div className="col s6 l4">
                                                <label htmlFor="empresaPrepaga" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Empresa Prepaga</label>
                                                <select style={{ fontWeight: "bold", marginBottom: "1px" }} className="browser-default validate" id="empresaPrepaga" name="empresaPrepaga" defaultValue="0" onChange={(e) => setEPElegida(parseInt(e.target.value))} required >
                                                    <option value="0" disabled style={{ fontWeight: "bold" }}>Elegí una opcion</option>
                                                    {todasEP.map((e, index) => {
                                                        return (
                                                            <option key={index} value={e.Id} style={{ fontWeight: "bold" }}>{e.Nombre}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        )}

                                        {(OSyEPElegida === "2" || OSyEPElegida === "3") && (
                                            <div className="col s6 l4">
                                                <label htmlFor="empresaPrepaga" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Obra Social</label>
                                                <select style={{ fontWeight: "bold", marginBottom: "1px" }} className="browser-default validate custom-select" id="obraSocial" name="obraSocial" defaultValue="0" onChange={(e) => setOSElegida(parseInt(e.target.value))} required >
                                                    <option value="0" disabled style={{ fontWeight: "bold" }}>Elegí una opcion</option>
                                                    {todasOS.map((e, index) => {
                                                        return (
                                                            <option key={index} value={e.Id} style={{ fontWeight: "bold" }}>{e.Nombre}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        )}

                                        {(OSyEPElegida != "" && planesPosibles.length > 0) && (
                                            <>
                                                <div className="col s6 l4">
                                                    <label htmlFor="nroPlan" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Numero de Plan</label>
                                                    <select style={{ fontWeight: "bold", margin: "1px" }} className="browser-default validate custom-select" id="nroPlan" name="nroPlan" defaultValue="0" onChange={(e) => setIdNroPlan(parseInt(e.target.value))} required>
                                                        <option value="0" disabled style={{ fontWeight: "bold" }}>Elegí una opcion</option>
                                                        {planesPosibles.map((e, index) => {
                                                            return (
                                                                <option key={index} value={e.Id} style={{ fontWeight: "bold" }}>{e.Nombre}</option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>

                                                <div className="col s6 l4">
                                                    <label htmlFor="numeroAfiliado" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Numero de Afiliado</label>
                                                    <input style={{ fontWeight: "bold", margin: "0px" }} id="numeroAfiliado" name="numeroAfiliado" type="text" className="validate" maxLength="30" required onChange={(e) => setNroAfilado(e.target.value)} />
                                                </div>
                                            </>
                                        )}
                                        {errorOSEP && <span className='error-message'>ERROR. Completa toda la informacion referida a obra social o medicina prepaga</span>}
                                        {errorPac && <span className='error-message'>Error. ya existe paciente con ese mail o ese nro de telefono</span>} */}
                                    </>
                                )}

                                <div className='col s12'>
                                    <p></p>
                                </div>

                                <div className="col s6 l4">
                                    <label htmlFor="especialidad" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Especialidad requerida del turno</label>
                                    <select style={{ fontWeight: "bold", margin: "1px" }} className="browser-default validate" id="especialidad" name="especialidad" defaultValue="" {...register('especialidad', { required: true })}
                                        onChange={(e) => {
                                            const especialidadSeleccionada = todasEspecialidades.find(
                                                (especialidad) => especialidad.Id === Number(e.target.value)
                                            );
                                            setEspecialidadElegida(especialidadSeleccionada?.Nombre || '');
                                            setDatosEspElegida(especialidadSeleccionada || null)
                                        }}
                                    >
                                        <option value="" disabled style={{ fontWeight: "bold" }}>Elegí una opcion</option>
                                        {todasEspecialidades.map((e, index) => {
                                            return (
                                                <option key={index} value={e.Id} style={{ fontWeight: "bold" }}>{e.Nombre}</option>
                                            )
                                        })}
                                    </select>
                                    {errors.especialidad && <span className='error-message'>ERROR. Por favor elegí una especialidad para poder solicitar el turno</span>}
                                </div>

                                <div className="col s6 l4">
                                    <label htmlFor="ciudad" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Donde queres tomar tu consulta?</label>
                                    <select disabled={!especilaidadElegida || !allCiudades || allCiudades.length === 0} style={{ fontWeight: "bold", margin: "1px" }} className="browser-default validate" id="ciudad" name="ciudad" defaultValue="" {...register('ciudad', { required: true })} onChange={(e) => setCiudad(e.target.options[e.target.selectedIndex].text)}>
                                        <option value="" disabled style={{ fontWeight: "bold" }}>Elegí una opcion</option>
                                        {allCiudades.map((e, index) => (
                                            <option key={index} value={e.Id} style={{ fontWeight: "bold" }}>{e.Nombre}</option>
                                        ))}
                                    </select>
                                    {errors.ciudad && <span className='error-message'>ERROR. Por favor elegí un lugar de atención para poder solicitar el turno</span>}
                                </div>

                                <div className='col s12'>
                                    <label htmlFor="motivo" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Motivo de consulta</label>
                                    <textarea style={{ fontWeight: "bold" }} id="domicilio" name="domicilio" className='materialize-textarea validate' {...register('motivo', { required: true, minLength: 10, maxLength: 150 })} />
                                    {errors.motivo && <span className='error-message'>ERROR. Por favor ingresá un motivo válido para poder solicitar el turno</span>}
                                </div>

                                {!eligioModalidad && (
                                    <div className='col s12'>
                                        <div className='col s4'>
                                            <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Modalidad de consulta</label>
                                        </div>
                                        <div className='pregunta-turno-container col s8'>
                                            <button type="button" onClick={() => { setModalidadElegida('presencial'); setEligioModalidad(true); setErrorModalidad(false); }}
                                                className={`botonModalidad ${modalidadElegida === 'presencial' ? 'selected' : ''}`} id='presencial' >
                                                PRESENCIAL
                                            </button>

                                            <button
                                                type="button"
                                                onClick={() => { setModalidadElegida('virtual'); setEligioModalidad(true); setErrorModalidad(false); }}
                                                className={`botonModalidad ${modalidadElegida === 'virtual' ? 'selected' : ''}`}
                                                id='virtual' >
                                                VIRTUAL
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {errorModalidad && <span className='error-message'>ERROR. Por favor elegí una modalidad para poder solicitar el turno</span>}

                                {eligioModalidad && (
                                    <div className='col s12 center'>
                                        <p className='text-center' id="texto-confirmacion-solicitud-turno">
                                            Vas a solicitar turno con un profesional de la salud
                                            especialista en {especilaidadElegida}, de la Ciudad de {ciudad}; en la modalidad {modalidadElegida},
                                            si no estás de acuerdo, pulsá el boton CAMBIAR.
                                        </p>
                                    </div>
                                )}

                                <div className='col s12 center'>
                                    <button type='button' className='btn waves-effect waves-light' id="boton-cambio" onClick={handleCambiar}>
                                        Cambiar
                                    </button>

                                    <button
                                        className='btn waves-effect waves-light'
                                        type="button"
                                        onClick={handleBack}
                                        id="boton-cambio"
                                    >
                                        VOLVER
                                    </button>

                                    <button type="submit" className='btn waves-effect waves-light' id='boton-solicitar' style={{ margin: " 4px 4px 60px" }} disabled={!eligioModalidad || hizoClick}>
                                        SOLICITAR TURNO
                                    </button>
                                </div>

                                <div className='col s12 center'>

                                </div>

                                <div className='col s12 center'>

                                </div>
                            </div>
                        </form>

                        {showModal && (
                            <Modal
                                title="TURNO SOLICITADO"
                                description="Ya solicitaste tu turno, pronto recibirás una oferta."
                                onClose={() => { handleCloseModal(); navigate("/bienvenida"); }}
                            />
                        )}
                        {(showModal && noHayMedicos) && (
                            <Modal
                                title="NO HAY PROFESIONALES"
                                description="Lo sentimos :( No hay profesionales de la salud en tu ciudad especialistas en lo que pediste, te recomendamos tomar tu turno en forma virtual con un profesional de otra ciudad."
                                onClose={() => { handleCloseModal(); navigate("/bienvenida"); }}
                            />
                        )}
                    </div>
                </>
            ) : (
                <SessionExpired
                    setEstaEnSesion={setEstaEnSesion}
                    setToken={setToken}
                    setUsuarioEnSesion={setUsuarioEnSesion}
                    setEstaEnAjustes={setEstaEnAjustes}
                    setEstaEnPregFrec={setEstaEnPregFrec}
                    setEstaEnContacto={setEstaEnContacto}
                />
            )}
        </>
    );
};

export { SolicitudTurnoParaOtro }
