// este componente lo pueden usar tanto los pacientes como los medicos para poder consultar toda la informacion
// referida a un turno, tal como su estado actual, quien es el profesional asignado a ese turno, donde es el 
// lugar de atencion, la modalidad, el motivo del turno, etc

import React, { useState, useEffect } from 'react';
import logoPTM from '../../../images/newLogoPTM.jpeg';
import './DetalleTurno.css';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { detailTurnoServices } from './services/detalleTurno.service';
import { SessionExpired } from '../../components/SessionExpired/SessionExpired';

const DetalleTurno = ({ usuarioEnSesion, token, setEstaEnSesion, setUsuarioEnSesion, setToken, setEstaEnAjustes, setEstaEnContacto, setEstaEnPregFrec }) => {
    const navigate = useNavigate();

    // estados referidos al parametro id del turno generado por el link (aplica para AsignacionTurno, OfertaTurno, y ambas pantallas de confirmacion de turno)
    const [turnoConsultado, setTurnoConsultado] = useState({})
    const { id } = useParams();
    const [tokenVencido, setTokenVencido] = useState(false)

    // efecto para persistencia de sesion
    useEffect(() => {
        const logguedUserJSON = window.localStorage.getItem("DoctorFy")
        if (logguedUserJSON) {
            const user = JSON.parse(logguedUserJSON);
            setUsuarioEnSesion(user);
            setToken(user.token)
            setEstaEnSesion(true);
        }
    }, [])

    const traerElTurno = async () => {
        if (window.location.pathname.includes('detalleTurno')) {
            if (usuarioEnSesion) {
                const resultado = await detailTurnoServices.getDetailTurno(id, token)
                if (resultado.error !== undefined || resultado.esCero) {
                    if (resultado.error) {
                        if (resultado.error === "TOKEN VENCIDO.") {
                            setTokenVencido(true);
                            return;
                        }
                    }
                    navigate("*")
                }
                setTurnoConsultado(resultado)
            }
        }
    }

    // traigo el turno a consultar
    useEffect(() => {
        traerElTurno()
    }, [usuarioEnSesion]);

    const handleBack = () => {
        navigate(-1)
    }

    return (
        <>
            {usuarioEnSesion !== undefined && !tokenVencido ? (
                <>
                    <div className="form-registro-container row">
                        <div className='col s12'>
                            <div className="row">
                                <div className='col s12 center'>
                                    <div className="img-container" id="imagen-en-formulario">
                                        <img src={logoPTM} alt="Logo Alimenta Salud" id="form-registro-img"></img>
                                    </div>
                                    <h5 id="header-form-registro">INFORMACION DEL TURNO</h5>
                                    <h5>Aqui podés consultar toda la información respecto al turno</h5>
                                </div>

                                <div className='col s12 center'>
                                    <button
                                        className='btn waves-effect waves-light'
                                        type="button"
                                        onClick={handleBack}
                                        id='botonVolverConsultarTurno'
                                    >
                                        VOLVER
                                    </button>
                                </div>

                                {turnoConsultado?.Motivo === undefined ? (
                                    <p>CARGANDO...</p>
                                ) : (
                                    <div className='col s12' id='lastTextCT'>
                                        <p className='info_detalle'>DATOS DEL TURNO:</p>
                                        <p className='info_detalle'>Motivo: {turnoConsultado?.Motivo}</p>
                                        <p className='info_detalle'>Modalidad: {turnoConsultado?.Modalidad}</p>
                                        <p className='info_detalle'>Fecha y Hora: {turnoConsultado?.Fecha} a las {turnoConsultado?.Hora}</p>
                                        <p className='info_detalle'>Especialidad: {turnoConsultado?.Especialidade?.Nombre}</p>
                                        <p className='info_detalle'>Estado del turno: {turnoConsultado?.EstadosTurno?.Nombre}</p>

                                        <br></br>
                                        <p className='info_detalle'>DATOS DEL PACIENTE SOLICITANTE: </p>
                                        <ul className='info_detalle'>
                                            <li className='info_detalle'>{turnoConsultado?.Usuario?.Nombre} {turnoConsultado?.Usuario?.Apellido}</li>
                                            {turnoConsultado?.Usuario?.NumeroAfiliado !== null ? (
                                                <>
                                                    <li className='info_detalle'>Numero de afilidado: {turnoConsultado?.Usuario?.NumeroAfiliado}</li>
                                                    {turnoConsultado?.Usuario?.Obras_Sociale !== null && (
                                                        <li className='info_detalle'>
                                                            <p className='info_detalle'>Obra social: {turnoConsultado?.Usuario?.Obras_Sociale?.Nombre}</p>
                                                            <p className='info_detalle'>Numero de plan de la obra social: {turnoConsultado?.Usuario?.NumerosPlane?.Nombre}</p>
                                                        </li>
                                                    )}
                                                    {turnoConsultado?.Usuario?.Empresas_Prepaga !== null && (
                                                        <li className='info_detalle'>
                                                            <p className='info_detalle'>Empresa de medicina prepaga: {turnoConsultado?.Usuario.Empresas_Prepaga?.Nombre}</p>
                                                            <p className='info_detalle'>Numero de plan de la empresa de medicina prepaga: {turnoConsultado?.Usuario?.NumerosPlane?.Nombre}</p>
                                                        </li>
                                                    )}
                                                </>
                                            ) : (
                                                <li className='info_detalle'>El solicitante del turno no tiene ni obra social ni empresa de medicina prepaga</li>
                                            )}
                                        </ul>

                                        <br></br>
                                        {turnoConsultado?.IdPacAAtender !== null ? (
                                            <>
                                                <p className='info_detalle'>DATOS DEL PACIENTE A ATENDER: </p>
                                                <ul className='info_detalle'>
                                                    <li className='info_detalle'>{turnoConsultado?.PacientesNoUsuario?.Nombre} {turnoConsultado?.PacientesNoUsuario?.Apellido}</li>
                                                    <li className='info_detalle'>DNI: {turnoConsultado?.PacientesNoUsuario?.DniPaciente}</li>
                                                    {turnoConsultado?.PacientesNoUsuario?.NumeroAfiliado !== null ? (
                                                        <>
                                                            <li className='info_detalle'>Numero de afiliado: {turnoConsultado?.PacientesNoUsuario?.NumeroAfiliado}</li>
                                                            {turnoConsultado?.PacientesNoUsuario?.Obras_Sociale !== null && (
                                                                <li className='info_detalle'>
                                                                    <p className='info_detalle'>Obra social: {turnoConsultado?.PacientesNoUsuario?.Obras_Sociale?.Nombre}</p>
                                                                    <p className='info_detalle'>Numero de plan de la obra social: {turnoConsultado?.PacientesNoUsuario?.NumerosPlane?.Nombre}</p>
                                                                </li>
                                                            )}
                                                            {turnoConsultado?.PacientesNoUsuario?.Empresas_Prepaga !== null && (
                                                                <li className='info_detalle'>
                                                                    <p className='info_detalle' >Empresa de medicina prepaga: {turnoConsultado?.PacientesNoUsuario?.Empresas_Prepaga?.Nombre}</p>
                                                                    <p className='info_detalle'>Numero de plan de la empresa de medicina prepaga: {turnoConsultado?.PacientesNoUsuario?.NumerosPlane?.Nombre}</p>
                                                                </li>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <li className='info_detalle'>El paciente no tiene ni obra social ni empresa de medicina prepaga</li>
                                                    )}
                                                </ul>
                                            </>
                                        ) : (
                                            <p className='info_detalle'>El paciente que solicito el turno es el que desea atenderse</p>
                                        )}

                                        <br></br>
                                        <p className='info_detalle'>DATOS DEL PRFOESIONAL: </p>
                                        {turnoConsultado?.IdProfesional !== null ? (
                                            <>
                                                <p className='info_detalle'>{turnoConsultado?.Profesionale?.Nombre} {turnoConsultado?.Profesionale?.Apellido}</p>
                                                <p className='info_detalle'>Numero de matrícula: {turnoConsultado?.Profesionale?.NumeroMatricula}</p>
                                                <p className='info_detalle'>Resumen Curricular: {turnoConsultado?.Profesionale?.ResumenCurricular}</p>
                                                <p className='info_detalle'>Especialidades que ejerce el profesional: </p>
                                                <ul className='info_detalle'>
                                                    {turnoConsultado?.Profesionale?.Especialidades?.map((esp, index) => (
                                                        <li className='info_detalle' key={index}>{esp.Nombre}</li>
                                                    ))}
                                                </ul>
                                            </>
                                        ) : (<p className='info_detalle'>NO HAY UN PROFESIONAL QUE HAYA ACEPTADO ESTE TURNO</p>)}

                                        <br></br>
                                        <p className='info_detalle'>LUGAR DE ATENCION DEL TURNO: </p>
                                        {turnoConsultado?.IdLugarAtencion !== null ? (
                                            <>
                                                <p className='info_detalle'>Lugar: {turnoConsultado?.AtencionLugare?.Nombre}</p>
                                                <p className='info_detalle'>Direccion: {turnoConsultado?.AtencionLugare?.Direccion}</p>
                                            </>
                                        ) : (<p className='info_detalle'>NO HAY UN LUGAR DE ATENCION ASIGNADO A ESTE TURNO</p>)}
                                    </div>
                                )}
                                <br></br>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                // Si el token expiró o no hay un usuario en sesión
                <SessionExpired
                    setEstaEnSesion={setEstaEnSesion}
                    setToken={setToken}
                    setUsuarioEnSesion={setUsuarioEnSesion}
                    setEstaEnAjustes={setEstaEnAjustes}
                    setEstaEnPregFrec={setEstaEnPregFrec}
                    setEstaEnContacto={setEstaEnContacto}
                />
            )}
        </>
    );
};

export { DetalleTurno }