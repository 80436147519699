import React, { useState, useEffect } from 'react';
import logoPTM from '../../../images/newLogoPTM.jpeg';
import './GridProfesional.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { turnosForProfesionalServices } from './services/turnosForProfesional.service';
import { SessionExpired } from '../../components/SessionExpired/SessionExpired';

// en este componente se mostraran todos aquellos turnos que le hayan solamente LLEGADO AL PROFESIONAL, es decir, que su estado sea el nro 1: SOLICITADO
const GridProfesional = ({ usuarioEnSesion, token, setEstaEnSesion, setUsuarioEnSesion, setToken, setEstaEnAjustes, setEstaEnContacto, setEstaEnPregFrec }) => {

    const navigate = useNavigate()
    const [turnosDelProfesional, setTurnosDelProfesional] = useState([]) // almacenar los turnos que esten en estado solicitado y que no hayan sido tomados por ningun otro doctor, que le hayan llegado al doctor logueado
    const [tokenVencido, setTokenVencido] = useState(false)

    // efecto para persistencia de sesion
    useEffect(() => {
        const logguedUserJSON = window.localStorage.getItem("DoctorFy")
        if (logguedUserJSON) {
            const user = JSON.parse(logguedUserJSON);
            setUsuarioEnSesion(user);
            setToken(user.token)
            setEstaEnSesion(true);
        }
    }, [])

    const traerTurnosDeProfesional = async () => {
        if (usuarioEnSesion) {
            const response = await turnosForProfesionalServices.getTurnosForProfesional(usuarioEnSesion.Id, usuarioEnSesion.IdCiudad, token)
            if (response.error) {
                if (response.error === "TOKEN VENCIDO.") {
                    setTokenVencido(true);
                    return;
                }
            }
            setTurnosDelProfesional(response)
        }
    }

    // traer los turnos del backend y almacenarlos en el array declarado arriba
    useEffect(() => {
        traerTurnosDeProfesional()
    }, [usuarioEnSesion])

    // redirigir a la pantalla de bienvenida del profesional, si este pulsa el boton volver
    const handleBack = () => {
        navigate('/bienvenida')
    }


    return (
        <>
            {usuarioEnSesion !== undefined && !tokenVencido ? (
                <>
                    <div className='col s12 center'>
                        <div className="img-container" id="imagen-en-formulario">
                            <img src={logoPTM} alt="Logo Alimenta Salud" id="form-registro-img"></img>
                        </div>
                        <h5 id="header-form-registro">MIS TURNOS</h5>
                    </div>

                    <div className='col s12 center'>
                        <button
                            className='btn waves-effect waves-light'
                            type="button"
                            onClick={handleBack}
                            style={{ marginBottom: '24px' }}
                        >
                            VOLVER
                        </button>
                    </div>

                    {/* SI AL PROFESIONAL LE LLEGARON TURNOS QUE NADIE MAS TOMO, RENDERIZAR LA TABLA, CASO CONTRARIO, MOSTRAR UN MENSAJE  */}
                    {turnosDelProfesional.error === undefined ? (
                        <div className="col s12 center-align" id='tablaTurnos'>
                            <table className="striped responsive-table" style={{ marginBottom: '100px' }}>
                                <thead>
                                    <tr>
                                        <th>FECHA</th>
                                        <th>HORA</th>
                                        <th>ESPECIALIDAD</th>
                                        <th>ESTADO</th>
                                        <th>MODALIDAD</th>
                                        <th>VER SOLICITUD</th>
                                        <th>VER DETALLE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {turnosDelProfesional.map((registro, index) => (
                                        <tr key={index}>
                                            <td style={{ fontWeight: "500" }}>{registro.Fecha}</td>
                                            <td style={{ fontWeight: "500" }}>{registro.Hora}</td>
                                            <td style={{ fontWeight: "500" }}>{registro.Especialidade.Nombre}</td>
                                            <td style={{ fontWeight: "500" }}>{registro.fueRechazadoPorPaciente ? 'Rechazado por Paciente' : registro.EstadosTurno.Nombre}</td>
                                            <td style={{ fontWeight: "500" }}>{registro.Modalidad}</td>
                                            <td>
                                                {/* POR AHORA EL BOTON VER DETALLE NO HACE NADA, PERO LA IDEA ES QUE TE LLEVE A UN COMPONENTE QUE PERMITA VER LOS DATOS DEL TURNO SIN PODER MODIFICAR LOS DATOS DE ESTE */}
                                                <button className='btn waves-effect waves-light' disabled={registro.IdEstadoTurno !== 1 || registro.fueRechazadoPorPaciente} onClick={() => navigate(`/solicitudTurnoProf/${registro.IdTurno}`)}>
                                                    VER SOLICITUD
                                                </button>
                                            </td>
                                            <td>
                                                {/* POR AHORA EL BOTON VER DETALLE NO HACE NADA, PERO LA IDEA ES QUE TE LLEVE A UN COMPONENTE QUE PERMITA VER LOS DATOS DEL TURNO SIN PODER MODIFICAR LOS DATOS DE ESTE */}
                                                <button className='btn waves-effect waves-light' disabled={registro.IdEstadoTurno !== 1 || registro.fueRechazadoPorPaciente} onClick={() => navigate(`/detalleTurno/${registro.IdTurno}`)}>
                                                    VER DETALLE
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (<div className="col s12 center-align"><h2>Por el momento no se han recibido solicitudes de turno para su especialidad</h2></div>)}
                </>
            ) : (
                < SessionExpired
                    setEstaEnSesion={setEstaEnSesion}
                    setToken={setToken}
                    setUsuarioEnSesion={setUsuarioEnSesion}
                    setEstaEnAjustes={setEstaEnAjustes}
                    setEstaEnPregFrec={setEstaEnPregFrec}
                    setEstaEnContacto={setEstaEnContacto}
                />
            )}
        </>
    );
};

export { GridProfesional };