import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useFecha } from '../../hooks/useFecha';
import { solicTurnoProfServices } from '../../../SolicitudTurno/SolicitudTurnoMedico/services/solicTurnoProf.service';
import { SessionExpired } from '../../../components/SessionExpired/SessionExpired';
import logoPTM from "../../../../images/newLogoPTM.jpeg"
import { pagos1Services } from './services/pagos1.service';

const Pagos1 = ({
    usuarioEnSesion,
    token,
    setEstaEnSesion,
    setUsuarioEnSesion,
    setToken,
    setEstaEnAjustes,
    setEstaEnContacto,
    setEstaEnPregFrec,
}) => {
    const navigate = useNavigate();
    const { fechaString, hora } = useFecha();

    // estados referidos al parametro id del turno generado por el link (aplica para AsignacionTurno, OfertaTurno, y ambas pantallas de confirmacion de turno)
    const [turnoSolicitado, setTurnoSolicitado] = useState({})
    const { id } = useParams();

    const [tokenVencido, setTokenVencido] = useState(false)
    const [precioDRFY, setPrecioDRFY] = useState(0)

    // efecto para persistencia de sesion
    useEffect(() => {
        const logguedUserJSON = window.localStorage.getItem("DoctorFy")
        if (logguedUserJSON) {
            const user = JSON.parse(logguedUserJSON);
            setUsuarioEnSesion(user);
            setToken(user.token)
            setEstaEnSesion(true);
        }
    }, [])

    const traerElTurno = async () => {
        if (window.location.pathname.includes('pagos1')) {
            if (usuarioEnSesion) {
                const resultado = await solicTurnoProfServices.getTurnoById(id, token)
                if (resultado.error !== undefined) {
                    if (resultado.error === "TOKEN VENCIDO.") {
                        setTokenVencido(true);
                        return;
                    }
                    if (resultado.esCero) {
                        navigate("*")
                    }
                }
                setTurnoSolicitado(resultado)
            }
        }
    }

    const traerPrecio = async () => {
        const precio = await pagos1Services.getPrecioDrFy(token)
        if (precio.error !== undefined) {
            if (precio.error === "TOKEN VENCIDO.") {
                setTokenVencido(true);
                return;
            }
        }

        setPrecioDRFY(precio)
    }

    // si esta en la pantalla de oferta de turno, seteando los datos como lo traido del backend
    useEffect(() => {
        traerElTurno()
        traerPrecio()
    }, [usuarioEnSesion])

    const handleBack = () => {
        navigate('/misOfertasTurnoPac')
    }


    return (
        <>
            {usuarioEnSesion !== undefined && !tokenVencido ? (
                <>
                    <div className="form-registro-container row">
                        <div className="row">
                            <div className='col s12 center'>
                                <div className="img-container" id="imagen-en-formulario">
                                    <img src={logoPTM} alt="Logo Alimenta Salud" id="form-registro-img"></img>
                                </div>
                                <h5 id="header-form-registro">PAGAR TURNO</h5>
                            </div>

                            {(turnoSolicitado?.Usuario !== undefined) && (   // usuarioEnSesion !== null && 
                                <>
                                    <p className='info-oferta'>Fecha: {fechaString}. HORA: {hora}</p>
                                    <p className='info-oferta'>Estimado/a: {turnoSolicitado?.Usuario?.Nombre}  {turnoSolicitado?.Usuario?.Apellido}</p>
                                    <p className='info-oferta'>Estás aceptando la oferta de turno formulada por el/la Dr/Dra. {turnoSolicitado?.Profesionale?.Nombre}  {turnoSolicitado?.Profesionale?.Apellido} cuyo número de matrícula es: {turnoSolicitado?.Profesionale?.NumeroMatricula}</p>
                                    <p className='info-oferta'>Para el {turnoSolicitado?.Fecha} a las {turnoSolicitado?.Hora} en la modalidad {turnoSolicitado?.Modalidad}</p>
                                    <p></p>
                                    {turnoSolicitado?.Modalidad === "Presencial" ? (
                                        <>
                                            <p className='info-oferta'>Debes presentarte en {turnoSolicitado?.AtencionLugare?.Nombre} ubicado en {turnoSolicitado?.AtencionLugare?.Direccion} 15 minutos antes del turno</p>
                                        </>
                                    ) : (
                                        <p className='info-oferta'>El profesional se contactará con vos para tomar la consulta virtual en el dia y horario del turno</p>
                                    )}
                                </>
                            )}

                            <p className='info-oferta'>Ahora el sistema te conducirá a la pasarela de pago, en la cual deberás abonar un total de AR$ {(precioDRFY?.FeeUso) + (precioDRFY?.Honorarios_Prof)} en concepto de: </p>
                            <p className='info-oferta'>- Honorarios del profesional {turnoSolicitado?.Profesionale?.Nombre}  {turnoSolicitado?.Profesionale?.Apellido}, M.N. N°: {turnoSolicitado?.Profesionale?.NumeroMatricula}: AR$ {precioDRFY?.Honorarios_Prof}</p>
                            <p className='info-oferta'>- Cargo por uso de la plataforma DoctorFY: AR$ {precioDRFY?.FeeUso}</p>
                            <p className='info-oferta'>- IVA incluido</p>
                            <p className='info-oferta'>Si estás de acuerdo, pulsá PAGAR. Si no estás de acuerdo, pulsá VOLVER</p>
                            <div className='col s12 center' style={{ marginTop: "12px", marginBottom: "100px" }}>
                                <button
                                    className='btn waves-effect waves-light'
                                    type="button"
                                    onClick={() => navigate(`/pagos11/${id}`)}
                                    style={{ margin: '4px' }}
                                >
                                    PAGAR
                                </button>
                                <button
                                    className='btn waves-effect waves-light'
                                    type="button"
                                    onClick={handleBack}
                                    style={{ margin: '4px' }}
                                >
                                    VOLVER
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <SessionExpired
                    setEstaEnSesion={setEstaEnSesion}
                    setToken={setToken}
                    setUsuarioEnSesion={setUsuarioEnSesion}
                    setEstaEnAjustes={setEstaEnAjustes}
                    setEstaEnPregFrec={setEstaEnPregFrec}
                    setEstaEnContacto={setEstaEnContacto}
                />
            )}
        </>
    );
}

export { Pagos1 }
