// importando funcionalidades y librerias necesarias de react
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useFecha } from '../hooks/useFecha';
import axios from 'axios';
import DatePicker from 'react-datepicker'; // IMPORTACIONES NECESARIAS PARA QUE ANDE EL DATEPICKER
import 'react-datepicker/dist/react-datepicker.css';
import logoPTM from "../../../images/newLogoPTM.jpeg";
import "./AsignacionTurno.css";
import { useNavigate, useParams } from 'react-router-dom';
import { lugaresAtencionServices } from '../services/lugaresAtencion.service';
import { Modal } from '../../components/Modal/Modal.js';
import { solicTurnoProfServices } from '../../SolicitudTurno/SolicitudTurnoMedico/services/solicTurnoProf.service.js';
import { SessionExpired } from '../../components/SessionExpired/SessionExpired.js';


const AsignacionTurno = ({
    usuarioEnSesion,
    token,
    setEstaEnSesion,
    setUsuarioEnSesion,
    setToken,
    setEstaEnAjustes,
    setEstaEnContacto,
    setEstaEnPregFrec,
    setEligio,
    showModal,
    handleOpenModal,
    handleCloseModal,
}) => {
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const { minDate, maxDate } = useFecha(); // trayendo las fechas posibles para el datepicker

    // estados referidos al parametro id del turno generado por el link (aplica para AsignacionTurno, OfertaTurno, y ambas pantallas de confirmacion de turno)
    const [turnoSolicitado, setTurnoSolicitado] = useState({})
    const { id } = useParams();
    const [asigno, setAsigno] = useState(false)

    const [selectedDate, setSelectedDate] = useState(null);
    const [modalidadElegida, setModalidadElegida] = useState('');
    const [lugarAtencion, setLugarAtencion] = useState('');
    const [lugaresAtencion, setLugaresAtencion] = useState([]);
    const [errorLA, setErrorLA] = useState(false)
    const [tokenVencido, setTokenVencido] = useState(false)

    // efecto para persistencia de sesion
    useEffect(() => {
        const logguedUserJSON = window.localStorage.getItem("DoctorFy")
        if (logguedUserJSON) {
            const user = JSON.parse(logguedUserJSON);
            setUsuarioEnSesion(user);
            setToken(user.token)
            setEstaEnSesion(true);
        }
    }, [])

    const traerElTurno = async () => {
        if (window.location.pathname.includes('asignacionTurno')) {
            if (usuarioEnSesion) {
                const resultado = await solicTurnoProfServices.getTurnoById(id, token)
                if (resultado.error !== undefined) {
                    if (resultado.error === "TOKEN VENCIDO.") {
                        setTokenVencido(true);
                        return;
                    }
                    if (resultado.esCero) {
                        navigate("*")
                    }
                }
                setTurnoSolicitado(resultado)
            }
            setAsigno(false);
        }
    }

    // si esta en la pantalla de asignacion de turno,  setea los datos del turno como lo traido del backend
    useEffect(() => {
        traerElTurno()
    }, [usuarioEnSesion])


    // trayendo los lugares de atencion del profesional que se encuentra en sesion
    useEffect(() => {
        const traerLugaresAtencionDeProf = async () => {
            if (usuarioEnSesion) {
                const lugares = await lugaresAtencionServices.getLugaresAtencionByProf(token);
                setLugaresAtencion(lugares)
            }
        }
        traerLugaresAtencionDeProf()
    }, [usuarioEnSesion])

    // cada vez que cambie alguno de los atributos elegidos por el profesional, se ejecutara este efecto, que 
    // determina la ubicacion de los botones CAMBIAR y ACEPTAR
    // useEffect(() => {
    //     if ((modalidadElegida === 'Presencial' && lugarAtencion && selectedDate) || (modalidadElegida === 'Virtual' && selectedDate)) {
    //         setMarginTop('20px');
    //     } else {
    //         setMarginTop('160px')
    //     }
    // }, [modalidadElegida, lugarAtencion, selectedDate])

    // Funcion que se va a ejecutar si el usuario pulsa CAMBIAR, reseteando los datos del formulario.
    const handleChange = () => {
        reset();
        setSelectedDate(null);
        setModalidadElegida('');
        setLugarAtencion('');
    }

    // en cuanto el profesional pulse ASIGNAR se ejecuta esta funcion, que envia los datos al backend,
    // enviando la fehca, la hora, la modalidad, el lugar de atnecion (esto si la modalidad es presencial), y el nuevo estado de turno como ASIGNADO
    const onSubmit = async (data) => {
        // separando la fecha y la hora del turno (ya que en el datepicker estan como un unico atributo):
        const fechaTurno = data.fechaTurno.toISOString().split('T')[0];
        const horaTurno = data.fechaTurno.toTimeString().split(' ')[0];

        // configurando el objeto a enviar al backend
        let newData = {};
        if (data.IdLugarAtencion === undefined) { // si hay una ciudad de atencion en el objeto data al pulsar ASIGNAR
            newData = {
                Fecha: fechaTurno,
                Hora: horaTurno,
                Modalidad: data.Modalidad,
                IdEstadoTurno: 7 // estado ASIGNADO
            }
        } else {
            newData = {
                Fecha: fechaTurno,
                Hora: horaTurno,
                Modalidad: data.Modalidad,
                IdLugarAtencion: parseInt(data.IdLugarAtencion),
                IdEstadoTurno: 7 // estado ASIGNADO
            }
        }

        if (data.Modalidad === "Presencial" && data.IdLugarAtencion === undefined) {
            setErrorLA(true)
            return;
        }
        setErrorLA(false)

        // funcion que envia el objeto con los datos a actualizar al backend usando la libreria axios.
        // await axios.put(`${apiUrl}/api/plataforma_medica/turnos/actualizar/${turnoSolicitado.IdTurno}`, newData)
        const response = await solicTurnoProfServices.updateTurno(turnoSolicitado.IdTurno, newData, token)
        if (response.error === "TOKEN VENCIDO.") {
            setTokenVencido(true);
            return;
        }
        setSelectedDate(null);
        setModalidadElegida('');
        setLugarAtencion('');
        // setMarginTop('160px');
        reset();
        handleOpenModal();
        setAsigno(true)

    };

    // cada vez que se cambie la fecha en el datepicker, se ejecuta esta funcion, que es basicamente un setter de estados
    const handleDateChange = (date) => {
        setSelectedDate(date);
        setValue('fechaTurno', date, { shouldValidate: true });
    };

    // funcion que se ejecuta si el usuario hace click en el boton VOLVER
    const handleBack = () => {
        reset();
        setSelectedDate(null);
        setModalidadElegida('');
        setLugarAtencion('');
        // setMarginTop('160px');
        setEligio(true)
        navigate('/misTurnosAceptadosProf')
    }

    return (
        <>
            {usuarioEnSesion !== undefined && !tokenVencido ? (
                <>
                    <div className="form-registro-container row">
                        <form className="col s12" onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className='col s12 center'>
                                    <div className="img-container" id="imagen-en-formulario">
                                        <img src={logoPTM} alt="Logo Alimenta Salud" id="form-registro-img"></img>
                                    </div>
                                    <h5 id="header-form-registro">ASIGNACION DE TURNO</h5>
                                </div>
                                {turnoSolicitado?.Usuario !== undefined && (
                                    <>
                                        <div className='col s12 center info-solicitud' >
                                            <span className='info_asignacion '>ASIGNACION DE TURNO PARA: {turnoSolicitado?.Usuario?.Nombre}  {turnoSolicitado?.Usuario?.Apellido}</span>
                                        </div>
                                        <div className='col s12 center info-solicitud'>
                                            <span className='info_asignacion'>MOTIVO DE CONSULTA: {turnoSolicitado?.Motivo}</span>
                                        </div>
                                    </>
                                )}
                                <div className='col s12'>
                                    <label htmlFor="fechaTurno" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Ingresá la Fecha y Hora en la que querés realizar el turno</label>
                                    <DatePicker
                                        id="fechaTurno"
                                        selected={selectedDate}
                                        onChange={handleDateChange}
                                        showTimeSelect
                                        minDate={minDate}
                                        maxDate={maxDate}
                                        dateFormat="Pp"
                                        timeFormat="HH:mm:00"
                                        timeIntervals={30}
                                        className="datepicker validate"
                                        required
                                    // style={{ fontWeight: "bold" }}
                                    />
                                </div>

                                <div className="col s6">
                                    <label htmlFor="modalidad" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Elegí modalidad del turno:</label>
                                    <select style={{ fontWeight: "bold" }} className="browser-default validate" id="modalidad" name="modalidad" defaultValue="" {...register('Modalidad', { required: { value: true, message: "Error. Por favor elegí la modalidad del turno" } })} onChange={(e) => { setModalidadElegida(e.target.value) }} >
                                        <option value="" style={{ fontWeight: "bold" }} disabled>Elegí una opcion</option>
                                        <option style={{ fontWeight: "bold" }} value="Presencial">Presencial</option>
                                        <option style={{ fontWeight: "bold" }} value="Virtual">Virtual</option>
                                    </select>
                                    {errors.Modalidad && <span className='error-message'>{errors.Modalidad.message}</span>}
                                </div>


                                {modalidadElegida === 'Presencial' && (
                                    <>
                                        <div className="col s6">
                                            <label htmlFor="lugar" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Elegí donde querés atender el turno:</label>
                                            <select style={{ fontWeight: "bold" }} className="browser-default validate" id="lugar" name="lugar" defaultValue="" {...register('IdLugarAtencion', { required: { value: true, message: "Error. Por favor elegí dónde tomar el turno" } })} onChange={(e) => { setLugarAtencion(e.target.options[e.target.selectedIndex].text) }} >
                                                <option value="" style={{ fontWeight: "bold" }} disabled>Elegí una opcion</option>
                                                {lugaresAtencion.map((e, index) => (
                                                    <option key={index} value={e.AtencionLugare.Id} style={{ fontWeight: "bold" }}>{e.AtencionLugare.Nombre}</option>
                                                ))}
                                            </select>
                                            {errors.IdLugarAtencion && <span className='error-message'>{errors.IdLugarAtencion.message}</span>}
                                        </div>
                                    </>
                                )}
                                {errors.CiudadAtencion && <span className='error-message'>ERROR. Por favor elegí donde querés atender el turno</span>}
                                {(modalidadElegida === 'Presencial' && lugarAtencion && selectedDate) && (
                                    <>
                                        <p style={{ fontWeight: "500" }} className='text-center' id="texto-confirmacion-asignacion-turno">
                                            Vas a asignar el turno para el dia {selectedDate.toISOString().split('T')[0]} a las {selectedDate.toTimeString().split(' ')[0]} en {lugarAtencion} de forma {modalidadElegida}.
                                            Si estás seguro, pulsa ASIGNAR, si querés cambiar los datos, pulsá CAMBIAR.
                                        </p>
                                    </>
                                )}

                                {(modalidadElegida === 'Virtual' && selectedDate) && (
                                    <div className='col s12 center'>
                                        <p style={{ fontWeight: "500" }} className='text-center' id="texto-confirmacion-asignacion-turno">
                                            Vas a asignar el turno para el dia {selectedDate.toISOString().split('T')[0]} a las {selectedDate.toTimeString().split(' ')[0]} de forma {modalidadElegida}.
                                            Si estás seguro, pulsa ASIGNAR, si querés cambiar los datos, pulsá CAMBIAR.
                                        </p>
                                    </div>
                                )}

                                {errorLA && <div className='col s12 center'><span className='error-message'>ERROR. Por favor elegí donde querés atender el turno</span></div>}

                                <div className='col s12 center' style={{ marginTop: "20px", marginBottom: "100px" }}>
                                    <button type="button" className='btn waves-effect waves-light' id="boton_asignacion" onClick={handleChange} >
                                        Cambiar
                                    </button>

                                    <button
                                        className='btn waves-effect waves-light'
                                        type="button"
                                        onClick={handleBack}
                                        id="boton_asignacion"
                                    // style={{ marginBottom: "40px", marginTop: "8px" }}
                                    >
                                        VOLVER
                                    </button>

                                    <button type="submit" className='btn waves-effect waves-light' id="boton_asignacion" disabled={asigno || !modalidadElegida}>
                                        Asignar
                                    </button>
                                </div>
                            </div>
                        </form>
                        {showModal && (
                            <Modal
                                title="ASIGNASTE EL TURNO"
                                description="Ya asignaste el turno, porfavor estate atento a tu correo electrónico para ver la respuesta por parte del paciente."
                                onClose={() => { handleCloseModal(); navigate('/misTurnosAceptadosProf') }}
                            />
                        )}
                    </div>
                </>
            ) : (
                <SessionExpired
                    setEstaEnSesion={setEstaEnSesion}
                    setToken={setToken}
                    setUsuarioEnSesion={setUsuarioEnSesion}
                    setEstaEnAjustes={setEstaEnAjustes}
                    setEstaEnPregFrec={setEstaEnPregFrec}
                    setEstaEnContacto={setEstaEnContacto}
                />
            )}
        </>
    );
};

export { AsignacionTurno }