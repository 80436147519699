import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, useFormContext } from 'react-hook-form';
import axios from 'axios';
import "./IniciarSesion.css";
import useIp from './hooks/useIp';

// creando el componente de Inicio de Sesion
const IniciarSesion = ({
    setEstaEnSesion,
    setUsuarioEnSesion,
    apiUrl,
    setToken
}) => {
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [noExisteUsuario, setNoExisteUsuario] = useState(false); // validacion de si el usuario existe o no
    const ip = useIp();

    // funcion que se ejecuta si el usuario pulsa en volver
    const handleBack = () => {
        setEstaEnSesion(false);
        setUsuarioEnSesion(null);
    };

    // funcion que se va a ejecutar cuando el usuario pulse INICIAR SESION, enviando el email y la contraseña ingresadas al backend
    const onSubmit = async (data) => {
        // ver que contiene data

        data.ip = ip

        // pedirle al backend que haga la busqueda del usuario, si no existe ningun usuario qe cumpla la busqueda, retornar un mensaje de error
        const userOnline = await axios.post(`${apiUrl}/api/plataforma_medica/usuarios/login`, data);
        const userOnlineData = userOnline.data;
        if (userOnlineData.error !== undefined) {
            setNoExisteUsuario(true);
            return;
        };

        // indicar que un usuario ha iniciado sesion, ademas de guardar los datos de ese usuario
        setEstaEnSesion(true);
        setUsuarioEnSesion(userOnlineData);
        window.localStorage.setItem("DoctorFy", JSON.stringify(userOnlineData)); // guardo el usuario en localStorage para persistencia de sesion
        setToken(userOnlineData.token);

        // navegar hacia la pantalla de bienvenida que corresponda y resetear lo ingresado en el form de inicio de sesion
        navigate('/bienvenida')
        reset();
    }

    return (
        <div className='container-center' style={{ height: "100vh" }}>
            <div className="row">
                <div className='col s12 center'>
                    <h3 className='header-iniciar-sesion light-blue accent-2 white-text'>INICIAR SESION</h3>
                </div>
                <form className="col s12" onSubmit={handleSubmit(onSubmit)}>
                    <div className="col s12">
                        <label htmlFor="emailInicioSesion" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Correo Electronico</label>
                        <input id="emailInicioSesion" style={{ fontWeight: "bold" }} type="email" className="validate" {...register('email', { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })} />
                    </div>
                    {errors.email && <span className='error-message'>ERROR. Por favor ingresá un mail valido <br></br></span>}

                    <div className="col s12">
                        <label htmlFor="passwordInicioSesion" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Contraseña</label>
                        <input id="passwordInicioSesion" style={{ fontWeight: "bold" }} type="password" className="validate" {...register('password', { required: true, minLength: 4, maxLength: 15 })} />
                    </div>
                    {errors.password && <span className='error-message'>ERROR. Por favor ingresá una contraseña válida</span>}
                    {noExisteUsuario && <span className='error-message'>ERROR. Mail o contraseña incorrectos, intentá de nuevo</span>}

                    <div className='col s12 center'>
                        <p className='center-align'>Si sos Profesional de la salud y no tenés una cuenta, create tu cuenta <Link to="/medico">acá</Link></p>

                    </div>

                    <div className='col s12 center'>
                        <p className='center-align' style={{ marginBottom: "12px" }}>Si NO sos Profesional de la salud y no tenés una cuenta, create tu cuenta <Link to="/paciente">acá</Link></p>
                    </div>

                    <div className='col s12 center'>
                        <button
                            className="btn waves-effect waves-light light-green darken-2 btn-inicio-sesion"
                            type="submit"
                            name="action"
                        >
                            INICIAR SESION
                            <i className="material-icons right">send</i>
                        </button>
                    </div>

                    <div className='col s12 center'>
                        <a href='https://www.doctorfy.com.ar/'>
                            <button
                                className='btn waves-effect waves-light'
                                type="button"
                                onClick={handleBack}
                                style={{ marginTop: "20px", marginBottom: "40px" }}
                            >
                                VOLVER
                            </button>
                        </a>
                    </div>
                </form>
            </div>
        </div>
    );
};

export { IniciarSesion };