import React from 'react'
import { useNavigate } from 'react-router-dom'
import logoPTM from "../../images/newLogoPTM.jpeg";

const NoDisponible = () => {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1) // vuelve a la pantalla anterior a la que estaba antes de caer en esta
    }

    return (
        <div className="row">
            <div className="col s12 m6 offset-m3">
                <div className="logo-container center">
                    <img src={logoPTM} alt="Logo PTM" width="200" />
                </div>
                <h4 className="center-align" style={{ color: 'blue' }}>FUNCIONALIDAD TEMPORALMENTE NO DISPONIBLE</h4>
                <div className='col s12 center'>
                    <button
                        className='btn waves-effect waves-light'
                        type="button"
                        onClick={handleBack}
                        style={{ marginTop: "20px" }}
                    >
                        VOLVER
                    </button>
                </div>
            </div>
        </div>
    )
}

export { NoDisponible }