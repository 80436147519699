import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Modal } from '../../components/Modal/Modal.js';
import logoPTM from "../../../images/newLogoPTM.jpeg"
import { contactoServices } from '../services/contacto.service.js';
import { SessionExpired } from '../../components/SessionExpired/SessionExpired.js';

const ContactForm = ({
    usuarioEnSesion,
    token,
    setEstaEnSesion,
    setUsuarioEnSesion,
    setToken,
    setEstaEnAjustes,
    setEstaEnContacto,
    setEstaEnPregFrec,
    showModal,
    handleOpenModal,
    handleCloseModal
}) => {
    const navigate = useNavigate();
    const { register, reset, handleSubmit, formState: { errors } } = useForm()
    const [hizoClick, setHizoClick] = useState(false)
    const [tokenVencido, setTokenVencido] = useState(false)

    // efecto para persistencia de sesion
    useEffect(() => {
        const logguedUserJSON = window.localStorage.getItem("DoctorFy")
        if (logguedUserJSON) {
            const user = JSON.parse(logguedUserJSON);
            setUsuarioEnSesion(user);
            setToken(user.token)
            setEstaEnSesion(true);
            setEstaEnContacto(true)
        }
    }, [])

    const handleBack = () => {
        setEstaEnContacto(false)
        reset()
        setHizoClick(false)
        navigate(-1)
    }

    const onSubmit = async (data) => {
        setHizoClick(true)
        if (usuarioEnSesion) {
            if (usuarioEnSesion.usuarioComun) {
                data.nombre = usuarioEnSesion.usuarioComun.Nombre;
                data.apellido = usuarioEnSesion.usuarioComun.Apellido;
                data.correo = usuarioEnSesion.usuarioComun.Correo
            } else {
                data.nombre = usuarioEnSesion.Nombre
                data.apellido = usuarioEnSesion.Apellido;
                data.correo = usuarioEnSesion.Correo
            }
        }

        const response = await contactoServices.enviarMsjContacto(data);
        if (response.error) {
            if (response.error === "TOKEN VENCIDO.") {
                setTokenVencido(true);
                return;
            }
        }
        setTokenVencido(false)
        reset();
        handleOpenModal();
    }

    return (
        <>
            {usuarioEnSesion !== undefined && !tokenVencido ? (
                <>
                    <div>
                        <div className="logo-container center">
                            <img src={logoPTM} alt="Logo PTM" width="200" />
                        </div>

                        <h4 className='center-align' style={{ fontWeight: "600", color: "blue" }}>CONTACTO</h4>

                        <h4 className='center-align'>
                            HOLA: {usuarioEnSesion
                                ? (usuarioEnSesion.usuarioComun
                                    ? usuarioEnSesion.usuarioComun.Nombre?.toUpperCase()
                                    : usuarioEnSesion?.Nombre?.toUpperCase())
                                : "No estás logueado"
                            } {usuarioEnSesion
                                ? (usuarioEnSesion.usuarioComun
                                    ? usuarioEnSesion.usuarioComun.Apellido?.toUpperCase()
                                    : usuarioEnSesion?.Apellido?.toUpperCase())
                                : ""
                            }
                        </h4>
                    </div >

                    <div className='col s12'>
                        <label htmlFor="memo" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Que mensaje nos querés enviar?</label>
                        <textarea
                            type="text"
                            id="memo"
                            name="memo"
                            className='validate'
                            {...register
                                ('memo',
                                    {
                                        required: {
                                            value: true,
                                            message: "El mensaje es requerido."
                                        },
                                        minLength: {
                                            value: 10,
                                            message: "El mensaje debe tener una longitud mínima de 10 caracteres"
                                        },
                                        maxLength: {
                                            value: 999,
                                            message: "El mensaje debe tener una longitud máxima de 999 caracteres"
                                        }
                                    }
                                )
                            }
                        />
                    </div>
                    {errors.memo && <span className='error-message'>{errors.memo.message}</span>}

                    {!usuarioEnSesion && (
                        <>
                            <div className="col s12">
                                <label htmlFor="email" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Ingresá tu Correo Electrónico</label>
                                <input
                                    id="email"
                                    type="text"
                                    name="email"
                                    {...register("correo", {
                                        required: {
                                            value: true,
                                            message: "El correo electrónico es requerido."
                                        },
                                        pattern: {
                                            value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                                            message: "El correo electronico debe tener este formato: xxxxx@xxxxx.xxx"
                                        }
                                    })}
                                />
                            </div>
                            {errors.correo && <p className='error-message'>{errors.correo.message}</p>}
                        </>
                    )}


                    <div className="col s12 center">
                        <button
                            className='btn waves-effect waves-light'
                            id="msj"
                            type="submit"
                            onClick={handleSubmit(onSubmit)}
                            style={{ marginTop: "20px" }}
                            disabled={hizoClick}
                        >
                            ENVIAR
                            <i className='material-icons right large'>email</i>
                        </button>
                    </div>

                    <div className='col s12 center'>
                        <button
                            className='btn waves-effect waves-light'
                            type="button"
                            onClick={handleBack}
                            style={{ marginTop: "20px", marginBottom: "100px" }}
                        >
                            VOLVER
                        </button>
                    </div>

                    {showModal && (
                        <Modal
                            title="MENSAJE ENVIADO"
                            description="A la brevedad responderemos a tu inquietud."
                            onClose={() => { handleCloseModal(); handleBack(); }}
                        />
                    )}
                </>
            ) : (
                // Si el token expiró o no hay un usuario en sesión
                <SessionExpired
                    setEstaEnSesion={setEstaEnSesion}
                    setToken={setToken}
                    setUsuarioEnSesion={setUsuarioEnSesion}
                    setEstaEnAjustes={setEstaEnAjustes}
                    setEstaEnPregFrec={setEstaEnPregFrec}
                    setEstaEnContacto={setEstaEnContacto}
                />
            )}
        </>
    )
}

export { ContactForm }
