import React, { useEffect, useState } from 'react'; // importando funcionalidades, hooks, librerias, y custom hooks necesarios
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useLugares } from '../../hooks/useLugares';
import axios from 'axios';
import { useConfigPaciente } from '../../hooks/useConfigPaciente';
import './ConfigsPaciente.css'; // importando estilos e imagenes
import logoPTM from "../../../../images/newLogoPTM.jpeg";
import { configPacServices } from '../services/configPac.service';
import { SessionExpired } from '../../../components/SessionExpired/SessionExpired';

// declaracion del componente de configuracion para pacientes
const ConfigsPaciente = ({ apiUrl, usuarioEnSesion, setUsuarioEnSesion, setEstaEnAjustes, setEstaEnSesion, setToken, token, setEstaEnContacto, setEstaEnPregFrec }) => {
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm();
    const navigate = useNavigate();

    const {
        todasEmpresasPrepaga,
        todasObrasSociales,
        OSElegida,
        setOSElegida,
        EPElegida,
        setEPElegida,
        obraSocialYPrepaga,
        setObraSocialYPrepaga,
        planesPosibles,
        planElegido,
        setPlanElegido,
        nroAfiliado,
        setNumeroAfiliado
    } = useConfigPaciente(); // trayendo propiedades y funcionalidades del custom hook useConfigPaciente
    const {
        paises,
        estadosPaisEspecifico,
        ciudadesEstadoEspecifico,
        setPaisElegido,
        setCiudadElegida,
        setEstadoElegido,
        paisElegido,
        ciudadElegida,
        estadoElegido
    } = useLugares(); // trayendo propiedades y funcionalidades del custom hook useLugares

    // efecto para persistencia de sesion
    useEffect(() => {
        const logguedUserJSON = window.localStorage.getItem("DoctorFy")
        if (logguedUserJSON) {
            const user = JSON.parse(logguedUserJSON);
            setUsuarioEnSesion(user);
            setToken(user.token)
            setEstaEnSesion(true);
            setEstaEnAjustes(true)
        }
    }, [])

    useEffect(() => {
        const traerCiudadEstadoPaisInicial = async () => {
            if (usuarioEnSesion) {
                const data = await axios.get(`${apiUrl}/api/plataforma_medica/lugares/ciudad_estado_pais/${usuarioEnSesion.usuarioComun.IdCiudad}`)
                const data2 = data.data
                setPaisElegido(data2.IdPais)
                setCiudadElegida(data2.IdCiudad)
                setEstadoElegido(data2.IdEstado)
            }
        }
        traerCiudadEstadoPaisInicial()
    }, [usuarioEnSesion])

    useEffect(() => {
        if (usuarioEnSesion) {
            if (usuarioEnSesion.usuarioComun.IdEmpresaPrepaga && usuarioEnSesion.usuarioComun.IdObraSocial) {
                setEPElegida(usuarioEnSesion.usuarioComun.IdEmpresaPrepaga)
                setOSElegida(usuarioEnSesion.usuarioComun.IdObraSocial)
                setObraSocialYPrepaga('3')
                setPlanElegido(usuarioEnSesion.usuarioComun.IdNumeroPlan)
                setNumeroAfiliado(usuarioEnSesion.usuarioComun.NumeroAfiliado)
            } else if (usuarioEnSesion.usuarioComun.IdEmpresaPrepaga && !usuarioEnSesion.usuarioComun.IdObraSocial) {
                setEPElegida(usuarioEnSesion.usuarioComun.IdEmpresaPrepaga)
                setOSElegida(0)
                setObraSocialYPrepaga('1')
                setPlanElegido(usuarioEnSesion.usuarioComun.IdNumeroPlan)
                setNumeroAfiliado(usuarioEnSesion.usuarioComun.NumeroAfiliado)
            } else if (!usuarioEnSesion.usuarioComun.IdEmpresaPrepaga && usuarioEnSesion.usuarioComun.IdObraSocial) {
                setEPElegida(0)
                setOSElegida(usuarioEnSesion.usuarioComun.IdObraSocial)
                setObraSocialYPrepaga('2')
                setPlanElegido(usuarioEnSesion.usuarioComun.IdNumeroPlan)
                setNumeroAfiliado(usuarioEnSesion.usuarioComun.NumeroAfiliado)
            } else {
                setObraSocialYPrepaga('0')
            }
        }
    }, [usuarioEnSesion])

    // inicialmente, en el formulario, se setearan estos valores precargados, los cuales son traidos desde el backend
    useEffect(() => {
        // Actualizar los valores de los campos cuando se cargue el usuario,esto sera el valor inicial del data
        if (usuarioEnSesion) {
            setValue('email', usuarioEnSesion?.usuarioComun?.Correo || '');
            setValue('idCiudad', usuarioEnSesion?.usuarioComun?.Ciudad || 0);
            setValue('domicilio', usuarioEnSesion?.usuarioComun?.Domicilio || '');
            setValue('numeroTelefonico', usuarioEnSesion?.usuarioComun?.NumeroTelefono?.replace(/\+54/g, '') || '');
            // setValue('empresaPrepaga', parseInt(usuarioEnSesion?.usuarioComun?.IdEmpresaPrepaga) || 0);
            // setValue('obraSocial', parseInt(usuarioEnSesion?.usuarioComun?.IdObraSocial) || 0);
        }
    }, [usuarioEnSesion]);

    // funcion que se ejecuta si el usuario pulsa en el boton volver
    const handleBack = () => {
        reset();
        setEstaEnAjustes(false);
        navigate(-1);
    }

    // estados de errores
    const [errorOSEP, setErrorOSEP] = useState(false)
    const [errorResidencia, setErrorResidencia] = useState(false);
    const [tokenVencido, setTokenVencido] = useState(false)
    const [responseError, setResponseError] = useState(null)

    // esta funcion se ejecutara en cuanto el usuario haga click en ACTUALIZAR, esta funcion hace validaciones de errores
    // (aun incompletas), y envia los datos al backend
    const onSubmit = async (data) => {
        data.idCiudad = ciudadElegida
        if (data.password === "") {
            delete data.password
        }
        // data.idNumeroPlan = planElegido
        // validando si el usuario completo toda la informacion de pais/estado/ciudad
        if (data.idCiudad === 0) {
            setErrorResidencia(true)
            return;
        }
        setErrorResidencia(false)

        // validando si el usuario completo todo lo referido a su plan medico de salud
        if (obraSocialYPrepaga === '1' && (EPElegida === 0 || planElegido === 0 || nroAfiliado.trim() === "")) {
            setErrorOSEP(true);
            return
        };
        setErrorOSEP(false)

        if (obraSocialYPrepaga === '2' && (OSElegida === 0 || planElegido === 0 || nroAfiliado.trim() === "")) {
            setErrorOSEP(true);
            return
        };
        setErrorOSEP(false)

        if (obraSocialYPrepaga === '3' && (EPElegida === 0 || OSElegida === 0 || planElegido === 0 || nroAfiliado.trim() === "")) {
            setErrorOSEP(true);
            return
        };
        setErrorOSEP(false)

        // declarando el objeto que se va a enviar al backend
        const newData = {
            ...data,
            empresaPrepaga: EPElegida !== 0 ? EPElegida : null,
            obraSocial: OSElegida !== 0 ? OSElegida : null,
            idCiudad: parseInt(data.idCiudad),
            idNumeroPlan: planElegido ? planElegido : null,
            numeroAfiliado: nroAfiliado ? nroAfiliado : null
        };

        // Manejar la lógica de envío del formulario aquí con newData
        const response = await configPacServices.updatePaciente(token, newData, usuarioEnSesion?.usuarioComun?.Id)
        if (response.error) {
            if (response.error === "TOKEN VENCIDO.") {
                setTokenVencido(true);
                return;
            }
            setResponseError(response.error)
            return
        }
        setTokenVencido(false)
        setUsuarioEnSesion(response);
        window.localStorage.setItem("DoctorFy", JSON.stringify(response))
        reset()
        setEstaEnAjustes(false);
        navigate("/bienvenida")
    };

    return (
        <>
            {usuarioEnSesion !== undefined && !tokenVencido ? (
                <>
                    <div className="form-config-container row">
                        <form className="col s12" onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className='col s12 center'>
                                    <div className="img-container" id="imagen-en-formulario">
                                        <img src={logoPTM} alt="Logo Alimenta Salud" id="form-registro-img"></img>
                                    </div>
                                    <h4 id="header-form-registro">CONFIGURACION</h4>
                                </div>
                                <div className="col s6 l4">
                                    <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }} htmlFor="email">Ingresá tu Correo Electronico</label>
                                    <input id="email" type="email" name="email" style={{ fontWeight: "bold", marginBottom: "0px" }} className="validate" required {...register('email', {
                                        required: {
                                            value: true,
                                            message: "El correo electrónico es requerido."
                                        },
                                        pattern: {
                                            value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                                            message: "El correo electronico debe tener este formato: xxxxx@xxxxx.xxx"
                                        }
                                    })} />
                                    {errors.email && (

                                        <span className='error-message'>{errors.email.message}</span>

                                    )}
                                </div>

                                <div className="col s6 l4">
                                    <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }} htmlFor="numeroTelefonico">Ingresá tu Numero de Telefono</label>
                                    <input type="number" id="numeroTelefonico" style={{ fontWeight: "bold", marginBottom: "0px" }} name="numeroTelefonico" className='validate' {...register('numeroTelefonico', {
                                        required: {
                                            value: true,
                                            message: "ERROR. Por favor, ingresá tu número de teléfono"
                                        },
                                        minLength: {
                                            value: 9,
                                            message: "El número de teléfono debe tener al menos 9 caracteres"
                                        },
                                        maxLength: {
                                            value: 13,
                                            message: "El número de teléfono debe tener no más de 13 caracteres"
                                        }
                                    })} />
                                    {errors.numeroTelefonico && (

                                        <span className='error-message'>{errors.numeroTelefonico.message}</span>


                                    )}
                                </div>




                                <div className="col s6 l4">
                                    <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }} htmlFor="pais">País de Residencia</label>
                                    <select className="browser-default validate" style={{ marginBottom: "1px", fontWeight: "bold" }} id="pais" name="pais" required value={paisElegido} onChange={(e) => setPaisElegido(parseInt(e.target.value))}>
                                        <option value={0} disabled style={{ fontWeight: "bold" }}>Elegí una opcion</option>
                                        {paises.map((e, index) => (
                                            <option key={index} value={e.Id} style={{ fontWeight: "bold" }}>{e.Nombre}</option>
                                        ))}
                                    </select>
                                </div>

                                {paisElegido !== 0 && (
                                    <div className="col s6 l4">
                                        <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }} htmlFor="provincia">Provincia de Residencia</label>
                                        <select className="browser-default validate" style={{ marginBottom: "1px", fontWeight: "bold" }} id="provincia" name="provincia" value={estadoElegido} required onChange={(e) => setEstadoElegido(parseInt(e.target.value))} >
                                            <option value={0} disabled style={{ fontWeight: "bold" }}>Elegí una opcion</option>
                                            {estadosPaisEspecifico.map((e, index) => (
                                                <option key={index} value={e.Id} style={{ fontWeight: "bold" }}>{e.Nombre}</option>
                                            ))}
                                        </select>
                                    </div>
                                )}

                                {estadoElegido !== 0 && (
                                    <div className="col s6 l4">
                                        <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }} htmlFor="cuidad">Ciudad de Residencia</label>
                                        <select className="browser-default validate" style={{ marginBottom: "1px", fontWeight: "bold" }} id="ciudad" name="ciudad" required {...register('idCiudad')} value={ciudadElegida} onChange={(e) => setCiudadElegida(parseInt(e.target.value))}>
                                            <option value={0} disabled style={{ fontWeight: "bold" }}>Elegí una opcion</option>
                                            {ciudadesEstadoEspecifico.map((e, index) => (
                                                <option key={index} value={e.Id} style={{ fontWeight: "bold" }}>{e.Nombre}</option>
                                            ))}
                                        </select>

                                    </div>
                                )}

                                {errorResidencia && (
                                    <div className='col s12 center'>
                                        <span className='error-message-2'>ERROR. Por favor completá toda la informacion sobre tu residencia</span>
                                    </div>
                                )}


                                <div className='col s12'>
                                    <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }} htmlFor='domicilio'>Ingresá tu Domicilio</label>
                                    <textarea style={{ fontWeight: "bold", marginBottom: "4px" }} id="domicilio" name="domicilio" className='materialize-textarea validate' {...register('domicilio', {
                                        required: {
                                            value: true,
                                            message: "ERROR. Por favor, ingresá tu domicilio"
                                        },
                                        maxLength: {
                                            value: 100,
                                            message: "El domicilio no puede tener mas de 100 caracteres"
                                        }
                                    })} />
                                    {errors.domicilio && (
                                        <>
                                            <span className='error-message'>{errors.domicilio.message}</span>
                                        </>
                                    )}
                                </div>

                                {/* <>
                                    <div className='col s12'>
                                        <label htmlFor="eleccionEPuOS" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Tenés Obra Social o pagás alguna mensualidad a una empresa de medicina prepaga? "Si no tenes ninguna, no elijas"</label>
                                        <select className="browser-default validate" style={{ marginBottom: "1px", fontWeight: "bold" }} id="eleccionEPuOS" name="eleccionEPuOS" value={obraSocialYPrepaga} onChange={(e) => setObraSocialYPrepaga(e.target.value)}>
                                            <option value="0" disabled style={{ fontWeight: "bold" }}>Elegí una opcion</option>
                                            <option value="1" style={{ fontWeight: "bold" }}>Tengo Empresa Prepaga</option>
                                            <option value="2" style={{ fontWeight: "bold" }}>Tengo Obra Social</option>
                                            <option value="3" style={{ fontWeight: "bold" }}>Tengo Ambas</option>
                                        </select>
                                    </div>

                                    {(obraSocialYPrepaga === "1" || obraSocialYPrepaga === "3") && (
                                        <div className="col s6 l4">
                                            <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }} htmlFor="empresaPrepaga">Empresa Prepaga</label>
                                            <select
                                                className="browser-default validate"
                                                style={{ marginBottom: "1px", fontWeight: "bold" }}
                                                id="empresaPrepaga"
                                                name="empresaPrepaga"
                                                value={EPElegida}
                                                onChange={(e) => setEPElegida(parseInt(e.target.value))}
                                                required
                                            >
                                                <option value={0} disabled style={{ fontWeight: "bold" }}>Elegí una opcion</option>
                                                {todasEmpresasPrepaga.map((e, index) => {
                                                    return (
                                                        <option key={index} value={e.Id} style={{ fontWeight: "bold" }}>{e.Nombre}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    )}

                                    {(obraSocialYPrepaga === "2" || obraSocialYPrepaga === "3") && (
                                        <div className="col s6 l4">
                                            <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }} htmlFor="obraSocial">Obra Social</label>
                                            <select
                                                className="browser-default validate custom-select"
                                                id="obraSocial"
                                                style={{ marginBottom: "1px", fontWeight: "bold" }}
                                                name="obraSocial"
                                                value={OSElegida}
                                                // onChange={(e) => handleRespuestaRegistro('IdObraSocial', parseInt(e.target.value))} 
                                                onChange={(e) => setOSElegida(parseInt(e.target.value))}
                                                required
                                            >
                                                <option value={0} disabled style={{ fontWeight: "bold" }}>Elegí una opcion</option>
                                                {todasObrasSociales.map((e, index) => {
                                                    return (
                                                        <option key={index} value={e.Id} style={{ fontWeight: "bold" }}>{e.Nombre}</option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    )}

                                    {(obraSocialYPrepaga !== "" && (OSElegida !== 0 || EPElegida !== 0)) && (
                                        <>
                                            <div className="col s6 l4">
                                                <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }} htmlFor="nroPlan">Numero de Plan</label>
                                                <select
                                                    className="browser-default validate custom-select"
                                                    id="nroPlan"
                                                    name="nroPlan"
                                                    style={{ marginBottom: "1px", fontWeight: "bold" }}
                                                    value={planElegido}
                                                    onChange={(e) => { setPlanElegido(parseInt(e.target.value)) }}
                                                // required
                                                // {...register("idNumeroPlan", {
                                                //     required: {
                                                //         value: true,
                                                //         message: "ERROR. Porfavor, Elegí el plan que tengas"
                                                //     }
                                                // })}
                                                >
                                                    <option value={0} disabled style={{ fontWeight: "bold" }}>Elegí una opcion</option>
                                                    {planesPosibles.map((e, index) => {
                                                        return (
                                                            <option key={index} value={e.Id} style={{ fontWeight: "bold" }}>{e.Nombre}</option>
                                                        );
                                                    })}
                                                </select>
                                                {errors.idNumeroPlan && <span className='error-message-2'>{errors.idNumeroPlan.message}</span>}
                                            </div>


                                            <div className="col s12">
                                                <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }} htmlFor="numeroAfiliado">Ingresá tu Numero de Afiliado</label>
                                                <input
                                                    style={{ fontWeight: "bold", marginBottom: "4px" }}
                                                    id="numeroAfiliado"
                                                    name="numeroAfiliado"
                                                    type="text"
                                                    value={nroAfiliado}
                                                    onChange={(e) => setNumeroAfiliado(e.target.value)}
                                                // {...register("numeroAfiliado", {
                                                //     required: {
                                                //         value: true,
                                                //         message: "ERROR. Si tenés una obra social y/o pagas una empresa de medicia prepaga, tenés que indicar tu número de afiliado"
                                                //     },
                                                //     maxLength: {
                                                //         value: 30,
                                                //         message: "El número de afiliado no puede tener más de 30 caracteres"
                                                //     }
                                                // })}
                                                />
                                                {errors.numeroAfiliado && <span className='error-message-2'>{errors.numeroAfiliado.message}</span>}

                                            </div>
                                        </>
                                    )}
                                </>
                                {errorOSEP && (
                                    <div className='col s12 center'>
                                        <span className='error-message-2'>ERROR. Por favor completá toda la informacion sobre tus coberturas médicas</span>
                                    </div>
                                )} */}

                                <div className="col s12">
                                    <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }} htmlFor="newPassword">Querés Cambiar tu Contraseña?</label>
                                    <input id="newPassword" type="password" style={{ fontWeight: "bold", marginBottom: "4px" }} name="newPassword" className="validate" {...register('password', {
                                        minLength: {
                                            value: 4,
                                            message: "La contraseña debe tener como mínimo 4 caracteres"
                                        },
                                        maxLength: {
                                            value: 15,
                                            message: "La contraseña debe tener como máximo 15 caracteres"
                                        }
                                    })} />
                                    {errors.password && (
                                        <p className='error-message'>{errors.password.message}</p>
                                    )}
                                </div>


                                {responseError !== null && (
                                    <div className='col s12 center'>
                                        <span className='error-message-2'>ERROR. {responseError}</span>
                                    </div>
                                )}



                                <div className='col s12 center'>
                                    <button
                                        className='btn waves-effect waves-light'
                                        type="button"
                                        onClick={handleBack}
                                        style={{ marginTop: "20px", marginBottom: '20px' }}
                                    >
                                        VOLVER
                                    </button>
                                </div>

                                <div className="col s12 center">
                                    <button className='btn waves-effect waves-light btn-update-pac' type="submit">
                                        ACTUALIZAR
                                        <i className='material-icons right large'>account_box</i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </>
            ) : (
                // Si el token expiró o no hay un usuario en sesión
                <SessionExpired
                    setEstaEnSesion={setEstaEnSesion}
                    setToken={setToken}
                    setUsuarioEnSesion={setUsuarioEnSesion}
                    setEstaEnAjustes={setEstaEnAjustes}
                    setEstaEnPregFrec={setEstaEnPregFrec}
                    setEstaEnContacto={setEstaEnContacto}
                />
            )}
        </>
    )
};

export { ConfigsPaciente }