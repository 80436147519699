import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from "react-tooltip";
import "./BotonPreguntasFrec.css";

const BotonPreguntasFrec = ({ usuarioEnSesion, setEstaEnPregFrec }) => {
    const navigate = useNavigate();

    // si el usuario hace click en el boton de ajustes se ejecuta esta funcion
    const handleActivoPregFrec = () => {
        setEstaEnPregFrec(true); // indicar que el usuario entro a ajustes
        if (usuarioEnSesion.TipoUsuario === 'Estandar') {// si es paciente se dirige a esta ruta
            window.open('https://www.doctorfy.com.ar/faq.html', '_blank'); // abre una nueva ventana
        } else {
            navigate('/preguntasFrecuentesProf') // si es profesional se dirige a esta ruta
        }
    };

    return (
        <>
            <a
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Preguntas Frecuentes"
                id="boton-preg-frec"
            >
                <button
                    className='btn waves-effect waves-light blue darken-2'
                    id="boton-preg-frec"
                    onClick={handleActivoPregFrec}

                ><i className='medium material-icons' id="icono-preg-frec">help</i>
                </button>
                <Tooltip
                    id='my-tooltip'
                />
            </a>
        </>
    );
};

export { BotonPreguntasFrec }