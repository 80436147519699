import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import "./BotonCapacitaciones.css";

// llamar a esto en un futuro: BotonContacto
const BotonCapacitaciones = ({ link }) => {
    const navigate = useNavigate();

    return (
        <a
            data-tooltip-id="my-tooltip-cap"
            data-tooltip-content="Como usar DoctorFY?"
            id="boton-capacitaciones"
            target='_blank'
            href={link}
        >
            <button className='btn waves-effect waves-light grey darken-2' id="boton-capacitaciones">
                <i className='medium material-icons text-darken-1' id="icono-capacitaciones">local_library</i>
            </button>
            <Tooltip
                id='my-tooltip-cap'
            />
        </a>

    );
};

export { BotonCapacitaciones }