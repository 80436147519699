import M from 'materialize-css/dist/js/materialize.min.js';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip'; // importacion de la libreria react-tooltip para generar los tooltips, dichos tooltips funcionan tanto para dispositivos moviles como para computadoras
import { BotonPantallaPrincipal } from '../BotonPantallaPrincipal/BotonPantallaPrincipal.js'; // importacion de componentes que se utilizan en este componente de PantallaInicial
import logoPTM from "../../../../images/newLogoPTM.jpeg"; // importacion del logo de PTM y de estilos
import logoEngtrade from "../../../../images/ENGTRADE_LOGO.jpeg"
import logoATS from "../../../../images/Logo_ATS.jpeg";
import "./PantallaInicial.css";
import Buttons from '../../../Bienvenida/Buttons.js';

// creando el componente de la pantalla inicial
const PantallaInicial = ({ setEstaEnContacto }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const elems = document.querySelectorAll('.carousel');
    const instances = M.Carousel.init(elems, {
      duration: 300,
      indicators: true,
      fullWidth: true,
      numVisible: 6
    });

    const nextSlide = () => {
      const carouselInstance = M.Carousel.getInstance(elems[0]);
      carouselInstance.next();
    };

    // Establecer un intervalo para cambiar de imagen cada 5 segundos
    setInterval(nextSlide, 5000); // 5000 milisegundos = 5 segundos

    // Limpiar el intervalo cuando el componente se desmonta
  }, [])

  return (
    <>
      <Buttons setEstaEnContacto={setEstaEnContacto} />
      <div className="container-center">
        <div className='row'>
          <div className="img-container">
            <img src={logoPTM} alt="Logo Alimenta Salud" />
          </div>
          <div className='col s12 center'>
            <div className='col s4 center'>
              <BotonPantallaPrincipal onClick={() => { navigate("/registrarse"); }} tooltip="REGISTRARSE YA" icon="person_add" color="#1429FF" leyenda="REGISTRARSE YA" />
              <span className='center-text leyenda-icono'>REGISTRARSE YA</span>
            </div>
            <div className='col s4 center'>
              <BotonPantallaPrincipal onClick={() => { navigate('/inicioSesion'); }} tooltip="INICIAR SESION" icon="person_pin" color="#7DDCFF" leyenda="INICIAR SESION" />
              <span className='center-text leyenda-icono'>INICIAR SESION</span>
            </div>
            <div className='col s4 center'>
              <BotonPantallaPrincipal onClick={() => { navigate('/recuperarContrasena'); }} tooltip="RECUPERAR CONTRASEÑA" icon="storage" color="#A0A1AE" leyenda="RECUPERAR CONTRASEÑA" />
              <span className='center-text leyenda-icono'>RECUPERAR CONTRASEÑA</span>
            </div>
          </div>
          <div className='col s12 center carrousel'>
            <div className="carousel" id="car" >
              <a className="carousel-item" id="car-item" href="https://doctorfy.com.ar/" target='_blank' rel='noreferrer'><img src={logoPTM} alt='' /></a>
              <a className="carousel-item" id="car-item" href="http://engtradear.com/Engtrade/home2_sp.html" target='_blank' rel='noreferrer'><img src={logoEngtrade} alt='' /></a>
              <a className="carousel-item" id="car-item" href="http://alimentandotusalud.com.ar/ATSBOFF/" target='_blank' rel='noreferrer'><img src={logoATS} alt='' /></a>
              <a className="carousel-item" id="car-item" href="https://doctorfy.com.ar/" target='_blank' rel='noreferrer'><img src={logoPTM} alt='' /></a>
              <a className="carousel-item" id="car-item" href="http://engtradear.com/Engtrade/home2_sp.html" target='_blank' rel='noreferrer'><img src={logoEngtrade} alt='' /></a>
              <a className="carousel-item" id="car-item" href="http://alimentandotusalud.com.ar/ATSBOFF/" target='_blank' rel='noreferrer'><img src={logoATS} alt='' /></a>
            </div>
          </div>
        </div>
        <Tooltip effect="solid" />
      </div>
    </>
  )
};

export { PantallaInicial }

